import { BACKEND_URL, JWT_KEY } from '../config';
import { getWithExpiry } from './localStorageService';

export const getPaidForCustomerIds = async (year) => {
  const response = await fetch(`${BACKEND_URL}api/v1/orders?year=${year}`, {
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
  }).catch((err) => {
    console.error('Get Orders call failed:', err);
  });

  if (!response.ok) {
    console.log('Get Orders call failed: ', response);
    return;
  }

  const orders = await response.json();
  return orders.map(({ toCustomerId }) => toCustomerId);
};
