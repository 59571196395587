import React from 'react';
import { Table } from 'semantic-ui-react';
import CartLineItem from '../CartLineItem/CartLineItem';

const CartLineItems = ({ people, showAddress }) => {
  return (
    <Table.Body>
      {people.map((person) => (
        <CartLineItem
          key={person.customerId}
          {...person}
          showAddress={showAddress}
        />
      ))}
    </Table.Body>
  );
};

export default CartLineItems;
