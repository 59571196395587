import { BACKEND_URL, ORG_ID } from '../config';

export const getOrgInfo = async () => {
  const response = await fetch(`${BACKEND_URL}api/v1/org/na/${ORG_ID}/`, {
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
    },
  }).catch((err) => {
    console.error('Error:', err);
  });

  if (!response.ok) {
    console.log('ERROR Response for get organization: ', response);
    return;
  }

  return await response.json();
};
