import React, { useContext, useState } from 'react';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { PacmanLoader } from 'react-spinners';
import { Message } from 'semantic-ui-react';
import EZInput from '../../components/EZInput/EZInput';
import ForgotPasswordModal from '../../components/ForgotPasswordModal/ForgotPasswordModal';
import { BACKEND_URL } from '../../config';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { SHALOCH_MANOS_ROUTE } from '../../routes';
import { writeOnLogin } from '../../services/loginService';
import './LoginPage.css';
import { getCart } from '../../services/cartService';
import { CartContext } from '../../contexts/CartProvider';

const LoginPage = (props) => {
  const { setGreetingName } = useContext(AuthenticationContext);
  const history = useHistory();
  const location = useLocation();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { setPhonenumber } = useContext(AuthenticationContext);
  const [fieldErrors, setFieldErrors] = useState({});
  const [error, setError] = useState('');
  const [verifying, setVerifying] = useState(false);
  const [pacmanColor, setPacmanColor] = useState('#e5bb34');
  const [openForgotPassword, setOpenForgotPassword] = useState(false);
  const { authenticated } = useContext(AuthenticationContext);
  const { from } = location.state || { from: { pathname: SHALOCH_MANOS_ROUTE } };
  const { setCart, setCartInitialized } = useContext(CartContext);

  const handleChangeEmail = (e) => {
    const newValue = e.target.value;
    setFieldErrors({ ...fieldErrors, email: newValue ? '' : 'Required' });
    setEmail(newValue);
  };

  const handleChangePassword = (e) => {
    const newValue = e.target.value;
    setFieldErrors({ ...fieldErrors, password: newValue ? '' : 'Required' });
    setPassword(newValue);
  };

  const login = (e) => {
    e.preventDefault();
    setVerifying(true);
    setError('');

    setTimeout(async () => {
      const response = await fetch(`${BACKEND_URL}api/v1/auth/login`, {
        method: 'POST',
        mode: 'cors', // no-cors, *cors, same-origin
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: email.trim(),
          password: password.trim(),
        }),
      }).catch((err) => {
        setVerifying(false);
        console.error('Error:', err);
        setError(err);
      });

      if (!response) {
        setVerifying(false);
        return;
      }

      const {
        accessToken,
        customerId,
        lastName,
        title,
        expiresIn,
        phoneNumber,
        error: loginError,
        errors,
        message: loginErrorMessage,
      } = await response.json();

      if (loginError || errors) {
        let errorMsg = errors ? errors.join(', ') : '';
        if (loginError) {
          errorMsg = `${loginError}: ${loginErrorMessage}`;
        }
        errorMsg = errorMsg.charAt(0).toUpperCase() + errorMsg.slice(1);
        setError(`${errorMsg}`);
        setVerifying(false);
        return;
      }

      const greetingName = `${title} ${lastName}`;
      writeOnLogin({
        accessToken,
        customerId,
        greetingName,
        lastName,
        phoneNumber,
        expiresIn,
      });
      setGreetingName(greetingName);
      setPhonenumber(phoneNumber)
      setVerifying(false);
      const cart = await getCart();
      setCart(cart);
      setCartInitialized(true);
      history.replace(from);
    }, 500);
  };

  if (authenticated) {
    return <Redirect to={SHALOCH_MANOS_ROUTE} />;
  }

  return (
    <>
      <ForgotPasswordModal
        open={openForgotPassword}
        setOpen={setOpenForgotPassword}
      />
      <div className="login-page">
        <div className="login-form">
          <div className="sign-in-title">Login</div>
          <div className="sign-in-subtitle">
            Please enter your email address and password to proceed.
            {error && (
              <Message className="login-error" negative>
                <Message.Header>Error</Message.Header>
                <p>{error}</p>
              </Message>
            )}
          </div>

          <form onSubmit={login}>
            <div className="email-pass-container">
              <EZInput
                errors={fieldErrors}
                className="email-field"
                label="Email Address"
                labelClassName="code"
                name="email"
                value={email}
                handleValueChange={handleChangeEmail}
                inputProps={{ placeholder: 'name@email.com' }}
              />
              <EZInput
                errors={fieldErrors}
                className="password-field"
                type="password"
                label="Password"
                labelClassName="code"
                name="password"
                value={password}
                handleValueChange={handleChangePassword}
                inputProps={{ placeholder: 'Enter your password' }}
              />
            </div>
            <button
              onMouseEnter={() => setPacmanColor('#071631')}
              onMouseLeave={() => setPacmanColor('#e5bb34')}
              className="login-in-btn"
              type="submit"
              disabled={!(email && password)}
            >
              {verifying ? (
                <div
                  style={{
                    width: '30px',
                    marginTop: '-10px',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    padding: '10px 50px',
                  }}
                >
                  <PacmanLoader size={10} loading={true} color={pacmanColor} />
                </div>
              ) : (
                <span>Login</span>
              )}
            </button>

            <div
              className="forgot-password-link"
              onClick={() => setOpenForgotPassword(true)}
            >
              Forgot Username or Password
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default LoginPage;
