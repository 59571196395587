import React, { useState } from 'react';
import cn from 'classnames';
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import './PlacesAutocomplete.css';
import EZInput from './EZInput/EZInput';

const PlacesAutocomplete = ({
  initialAddress,
  onAddressChange,
  errors = {},
}) => {
  const {
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      componentRestrictions: {
        country: 'us',
      },
    },
    defaultValue: initialAddress,
    debounce: 300,
  });

  const [currentSuggestion, setCurrentSuggestion] = useState(-1);

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = (e) => {
    // Reset active suggestion
    setCurrentSuggestion(-1);
    setValue(e.target.value);
    onAddressChange({ address: e.target.value });
  };

  const selectSuggestion = async ({ description, place_id: googlePlaceId }) => {
    clearSuggestions();
    setValue(`${value} ... Looking up zipcode ...`, false);
    const geocodeResults = await getGeocode({ address: description });
    const zipCode = await getZipCode(geocodeResults[0]);

    const lastCommaIndex = description.lastIndexOf(',');
    const newValue = `${description.slice(0, lastCommaIndex)} ${zipCode}`;
    setValue(newValue, false);
    onAddressChange({ address: newValue, googlePlaceId });
  };

  const handleUpDownArrows = (e) => {
    if (!data || data.length === 0) {
      return;
    }

    // up arrow
    if (e.keyCode === 38 && currentSuggestion > 0) {
      setCurrentSuggestion(currentSuggestion - 1);
    }
    // down arrow
    else if (e.keyCode === 40 && currentSuggestion < data.length - 1) {
      setCurrentSuggestion(currentSuggestion + 1);
    }
    // Enter
    else if (e.keyCode === 13) {
      e.preventDefault();
      selectSuggestion(data[currentSuggestion]);
    }
  };

  const renderSuggestions = () =>
    data.map((suggestion, i) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li
          key={place_id}
          onClick={() => selectSuggestion(suggestion)}
          className={cn({ active: currentSuggestion === i })}
        >
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  // Mispell address on purpose to get rid of 'Manage Address' in Chrome
  return (
    <div ref={ref} style={{ width: '100%' }}>
      <EZInput
        errors={errors}
        className="addres-field"
        label="S&zwnj;treet Addres&zwnj;s"
        labelClassName="code"
        name="addres"
        value={value}
        handleValueChange={handleInput}
        handleKeyDown={handleUpDownArrows}
        inputProps={{
          placeholder: 'Start typing address...',
          autoComplete: 'off',
        }}
      />

      {status === 'OK' && (
        <ul className="address-suggestions">{renderSuggestions()}</ul>
      )}
    </div>
  );
};

export default PlacesAutocomplete;
