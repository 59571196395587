import React from 'react';
import { Label } from 'semantic-ui-react';
import './StaffCategoryBadges.css';

const StaffCategoryBadges = ({ labelName, staffCategoryIds }) => {
  return staffCategoryIds.map((categoryId) => (
    <Label key={categoryId} className="category-badge">
      {labelName(categoryId)}
    </Label>
  ));
};
export default StaffCategoryBadges;
