import {
  BACKEND_URL,
  CUSTOMER_ID,
  JWT_KEY,
  USER_FULLNAME_KEY,
  USER_LASTNAME_KEY,
  USER_PHONE_NUMBER
} from '../config';
import { setWithExpiry } from './localStorageService';

export const invisibleLogin = async ({
  email,
  password,
  setAuthenticated,
  setGreetingName,
  setLastName,
  setPhonenumber,
}) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/login`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, password }),
  }).catch((err) => {
    console.error('Error:', err);
    return false;
  });

  if (!response.ok) {
    console.error('Could not login: ', await response.text());
    return false;
  }

  const {
    accessToken,
    customerId,
    lastName,
    title,
    expiresIn,
    message: loginErrorMessage,
    phoneNumber
  } = await response.json();

  if (loginErrorMessage) {
    console.log('Could not log in: ', loginErrorMessage);
    return false;
  }
  const greetingName = `${title} ${lastName}`;
  writeOnLogin({ accessToken, customerId, greetingName, lastName, phoneNumber, expiresIn });
  setGreetingName(greetingName);
  setLastName(lastName);
  setAuthenticated(true);
  setPhonenumber(phoneNumber);
  return true;
};

export const uuidLogin = async ({ uuid, setGreetingName, setLastName, setPhonenumber }) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/login?uuid=${uuid}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Referer: 'https://ezpurim.herokuapp.com',
    },
  }).catch((err) => {
    console.log('Error during login: ', err);
    return false;
  });

  if (!response.ok) {
    console.error('Could not login: ', await response.text());
    return false;
  }

  const {
    accessToken,
    expiresIn,
    message: loginErrorMessage,
    lastName,
    customerId,
    title,
    phoneNumber
  } = await response.json();

  if (loginErrorMessage) {
    console.log('Could not log in: ', loginErrorMessage);
    return false;
  }
  const greetingName = `${title} ${lastName}`;
  writeOnLogin({ accessToken, customerId, greetingName, lastName, phoneNumber, expiresIn });
  setGreetingName(greetingName);
  setLastName(lastName);
  setPhonenumber(phoneNumber)
  return true;
};

export const writeOnLogin = ({
  accessToken,
  customerId,
  greetingName,
  lastName,
  phoneNumber,
  expiresIn,
}) => {
  setWithExpiry(JWT_KEY, accessToken, expiresIn);
  setWithExpiry(CUSTOMER_ID, customerId, expiresIn);
  setWithExpiry(USER_FULLNAME_KEY, greetingName, expiresIn);
  setWithExpiry(USER_LASTNAME_KEY, lastName, expiresIn);
  setWithExpiry(USER_PHONE_NUMBER, phoneNumber, expiresIn);
};
