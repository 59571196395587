import React, { createContext, useEffect, useState } from 'react';
import { getOrgInfo } from '../services/orgService';

export const OrganizationContext = createContext();

export const OrganizationProvider = ({ children }) => {
  const [orgInfo, setOrgInfo] = useState({});

  useEffect(() => {
    (async () => {
      setOrgInfo(await getOrgInfo());
    })();
  }, []);

  return (
    <OrganizationContext.Provider value={orgInfo}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
