import React from 'react';
import { useHistory } from 'react-router-dom';
import { LOGIN_ROUTE, REGISTER_ROUTE } from '../../routes';
import './HeaderLandingPage.css';

const HeaderLandingPage = (props) => {
  const history = useHistory();

  return (
    <div className="header-landing-page">
      <button
        className="register-btn"
        onClick={() => history.push(REGISTER_ROUTE)}
      >
        <span>register</span>
      </button>
      <div className="header-login" onClick={() => history.push(LOGIN_ROUTE)}>
        login
      </div>
    </div>
  );
};

export default HeaderLandingPage;
