import React, { useContext, useEffect } from 'react';
import './EZHeader.css';
import ptaLogo from '../../images/PTAlogo.svg';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { CartContext } from '../../contexts/CartProvider';
import { useHistory, useLocation } from 'react-router-dom';
import { removeItem, getWithExpiry } from '../../services/localStorageService';
import {
  CONTINGENCY_ON,
  CUSTOMER_ID,
  JWT_KEY,
  PERSONAL_GREETINGS,
  USER_FULLNAME_KEY,
  USER_LASTNAME_KEY,
  USER_PHONE_NUMBER
} from '../../config';
import { Icon, Dropdown } from 'semantic-ui-react';
import {
  ACCOUNT_INFO_ROUTE,
  LANDING_ROUTE,
  LOGIN_ROUTE,
  SHALOCH_MANOS_ROUTE,
} from '../../routes';
import HeaderCart from '../HeaderCart/HeaderCart';
import HeaderLandingPage from '../HeaderLandingPage/HeaderLandingPage';
import { OrganizationContext } from '../../contexts/OrganizationProvider';

const EZHeader = (props) => {
  const {
    authenticated,
    setAuthenticated,
    subtitle,
    greetingName,
    showSubheader,
    setShowHelpModal,
  } = useContext(AuthenticationContext);

  const {
    hurryActive = false,
    hurryMessage,
    orderingActive = true,
    pickupMessage,
  } = useContext(OrganizationContext);

  const { orgInfo } = useContext(OrganizationContext);

  const { clearCart, setCartInitialized } = useContext(CartContext);
  const history = useHistory();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!orderingActive && pathname !== LANDING_ROUTE) {
      history.replace(LANDING_ROUTE);
    }
  }, [pathname, orderingActive]);

  const landingPage = pathname === LANDING_ROUTE && !CONTINGENCY_ON;
  const pekelachPage = pathname === SHALOCH_MANOS_ROUTE;
  const accountInfoPage = pathname === ACCOUNT_INFO_ROUTE;

  return (
    <>
      <div className="ezheader-container">
        <div className="ezheader">
          <img
            className="logo-img"
            src={ptaLogo}
            alt="EZPurim logo"
            onClick={() => history.push(SHALOCH_MANOS_ROUTE)}
          />
          <div
            className="logo-txt"
            onClick={() => history.push(SHALOCH_MANOS_ROUTE)}
          >
            <span className="logo-txt-prefix">EZ</span>
            <span>PURIM</span>
          </div>

          <div className="mobile-landing-header">
            {authenticated ||
              !orderingActive ||
              (landingPage && <HeaderLandingPage />)}
          </div>
          {authenticated && (
            <div className="mobile-area">
              <HeaderCart />
              <nav id="hamnav">
                <label htmlFor="hamburger">MORE&#8897;</label>
                <input type="checkbox" id="hamburger" />

                <div className="right-hand-side-mobile">
                  <>
                    {/* {accountInfoPage || (
                      <div
                        className="my-account"
                        onClick={() => history.push(ACCOUNT_INFO_ROUTE)}
                      >
                        <Icon name="user outline" />
                        my account
                      </div>
                    )} */}

                    <div onClick={() => setShowHelpModal(true)}>
                      <Icon name="help" />
                      help
                    </div>
                    <div
                      onClick={() => {
                        removeItem(JWT_KEY);
                        removeItem(USER_FULLNAME_KEY);
                        removeItem(USER_LASTNAME_KEY);
                        removeItem(PERSONAL_GREETINGS);
                        removeItem(CUSTOMER_ID);
                        removeItem(USER_PHONE_NUMBER);
                        setAuthenticated(false);
                        setCartInitialized(false);
                        clearCart();
                        history.push(LOGIN_ROUTE);
                      }}
                    >
                      logout
                    </div>
                  </>
                </div>
              </nav>
            </div>
          )}
          <div className="right-hand-side">
            {authenticated ||
              !orderingActive ||
              (landingPage && <HeaderLandingPage />)}
            {authenticated && (
              <>
                <HeaderCart />

                {/* {accountInfoPage || (
                  <div onClick={() => history.push(ACCOUNT_INFO_ROUTE)}>
                    <Icon name="user outline" />
                    my account
                  </div>
                )} */}

                <div onClick={() => setShowHelpModal(true)}>
                  <Icon name="help" />
                  help
                </div>
                {/* <div
                  onClick={() => {
                    removeItem(JWT_KEY);
                    removeItem(USER_FULLNAME_KEY);
                    removeItem(USER_LASTNAME_KEY);
                    removeItem(PERSONAL_GREETINGS);
                    removeItem(CUSTOMER_ID);
                    removeItem(USER_PHONE_NUMBER);
                    setCartInitialized(false);
                    clearCart();
                    setAuthenticated(false);
                    history.push(LOGIN_ROUTE);
                  }}
                >
                  logout
                </div> */}
                <div>
                  <Dropdown 
                    simple
                    direction='left'
                    trigger={
                      <span>
                        <Icon name='user' avatar size='large' /> 
                      </span>} 
                    icon={null}
                  >
                    <Dropdown.Menu>
                      <Dropdown.Item disabled>
                      <span>
                        Signed in as <strong>{getWithExpiry(USER_FULLNAME_KEY)}</strong>
                      </span>
                      </Dropdown.Item>
                      <Dropdown.Item 
                        icon='user outline' 
                        text='YOUR PROFILE' 
                        onClick={() => {
                          history.push(ACCOUNT_INFO_ROUTE)
                        }}
                      />
                      <Dropdown.Item 
                        icon='sign-out alternate' 
                        text='LOGOUT' 
                        onClick={() => {
                          removeItem(JWT_KEY);
                          removeItem(USER_FULLNAME_KEY);
                          removeItem(USER_LASTNAME_KEY);
                          removeItem(PERSONAL_GREETINGS);
                          removeItem(CUSTOMER_ID);
                          removeItem(USER_PHONE_NUMBER);
                          setCartInitialized(false);
                          clearCart();
                          setAuthenticated(false);
                          history.push(LOGIN_ROUTE);
                        }}
                      />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="ezheader-divider" />
      </div>

      {hurryActive && <div className="hurry-hurry">{hurryMessage}</div>}

      {authenticated && showSubheader && (
        <div className="subheader">
          <div className="subheader-title">Welcome {greetingName}</div>
          <div className="subheader-subtitle">{subtitle}</div>
          {pekelachPage && <div className="pickup-msg">{pickupMessage}</div>}
        </div>
      )}
    </>
  );
};

export default EZHeader;
