import { BACKEND_URL } from '../config';

export const registerAccountService = async ({ email, newPassword, pin }) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/validate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ email, newPassword, pin }),
  }).catch((err) => {
    console.error('Error:', err);
  });

  if (!response.ok) {
    const errorResponse = await response.json();
    console.log('ERROR Response: ', errorResponse);

    let errors = {};
    if (errorResponse.status === 'FORBIDDEN') {
      errors = { pin: errorResponse.message };
    } else if (errorResponse.status === 'CONFLICT') {
      errors = { userExists: true };
    } else if (errorResponse.status === 'NOT_FOUND') {
      errors = { userNotFound: errorResponse.message };
    }

    return { errors };
  }

  const accountInfo = await response.json();
  console.log('RESPONSE REASON: ', accountInfo);
  return { accountInfo };
};

export const registerCustomerService = async ({
  email,
  newPassword,
  customerId,
  orgId = 2,
}) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/register`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({ email, newPassword, orgId, customerId }),
  }).catch((err) => {
    console.error('Error:', err);
  });

  if (!response.ok) {
    console.log('ERROR Response: ', await response.text());
    return false;
  }

  return true;
};
