import React, { useContext, useEffect, useState } from 'react';
import './Pekelah.css';
import cn from 'classnames';
import { Icon, Label } from 'semantic-ui-react';
import { CartContext } from '../../contexts/CartProvider';
import { currencyDisplay } from '../../services/util';
import { useSpring, animated as a } from 'react-spring';
import { BACKEND_URL, JWT_KEY } from '../../config';
import { getWithExpiry } from '../../services/localStorageService';

const Pekelah = ({
  description,
  itemId,
  name: title,
  price,
  imageUrl,
  color,
}) => {

  useEffect(() => {
    fetchImage();
  }, []);

  const [img, setImg] = useState();

  const fetchImage = async () => {
    const response = await fetch(`${BACKEND_URL}api/v1/item/image/${itemId}`, {
      headers: {
        'Content-type': 'application/json',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
        'Accept-Encoding': 'gzip',
      },
    }).catch((errors) => {
      console.error('Error:', errors);
      return { errors };
    });
  
    if (!response.ok) {
      const errors = await response.text();
      console.log('ERROR Response: ', errors);
      return { errors };
    }
    const imageBlob = await response.blob();
    const imageObjectURL = URL.createObjectURL(imageBlob);
    setImg(imageObjectURL);
  };

  const [flipped, set] = useState(false);
  const { transform, opacity, pointerEvents } = useSpring({
    pointerEvents: flipped ? 'none' : 'auto',
    opacity: flipped ? 1 : 0,
    transform: `perspective(600px) rotateX(${flipped ? 180 : 0}deg)`,
    config: { mass: 5, tension: 500, friction: 80 },
  });

  const { cart, updateCart } = useContext(CartContext);
  const [displayExactQuantities, setDisplayExactQuanitites] = useState(true);

  useEffect(() => {
    const currentQuantity = cart.items?.[itemId] || 0;
    if (![0, 5, 10, 15, 20, 25].includes(currentQuantity)) {
      setDisplayExactQuanitites(false);
    }
  }, [cart.items, itemId]);

  const currentQuantity = cart.items?.[itemId] || 0;
  const fivePressed = currentQuantity === 5;
  const tenPressed = currentQuantity === 10;
  const fifteenPressed = currentQuantity === 15;
  const twentyPressed = currentQuantity === 20;
  const twentyFivePressed = currentQuantity === 25;

  const handleRemoveClicked = (e) => {
    if (flipped) {
      return;
    }
    e.stopPropagation();
    updateCart(itemId, 0);
  };

  const handleOtherQuantity = (e) => {
    if (flipped) {
      return;
    }

    e.stopPropagation();
    const newValue = e.target.value;
    updateCart(itemId, newValue.length > 0 ? parseInt(newValue) : newValue);
  };

  const handleQuantityClicked = (quantity) => (e) => {
    if (flipped) {
      return;
    }
    e.stopPropagation();

    const existingQuantity = cart.items[itemId];

    // If clicking the same quantity again - set to 0
    const newQuantity = existingQuantity === quantity ? 0 : quantity;
    updateCart(itemId, newQuantity);
  };

  return (
    <div className="kicks">
      <a.div
        style={{
          opacity,
          transform: transform.interpolate((t) => `${t} rotateX(180deg)`),
        }}
      >
        <div className="pekelah1">
          <div className="pekelah-top" />
          <div className="back-img-container">
            <div
              className="zoom-out-container"
              onClick={(e) => {
                set((state) => !state);
                e.stopPropagation();
              }}
            >
              <div className="zoom-out">
                <Icon name="zoom out" aria-label="Zoom Out" />
              </div>
              <div className="back-to-order">Back To Order</div>
            </div>

            <div className="back-img-div">
              <img className="back-img" src={img} alt="Pekelah" />
            </div>
          </div>
        </div>
      </a.div>
      <a.div
        className="pekelah-container"
        style={{
          opacity: opacity.interpolate((o) => 1 - o),
          transform,
          pointerEvents,
        }}
      >
        <div className="pekelah">
          <div className="pekelah-top" />
          <div className="img-rectangle">
            <div
              className="zoom-in"
              onClick={(e) => {
                set((state) => !state);
                e.stopPropagation();
              }}
            >
              <Icon name="zoom in" aria-label="Zoom In" />
            </div>
            <img src={img} alt="Pekelah" />
          </div>
          <div className="price-tag">
            <span>{currencyDisplay(price)} each</span>
          </div>
          <div className="pekelah-bottom">
            <div className="pekelah-title">
              {title}
              {Boolean(color) && (
                <Label
                  className="color-circle"
                  circular
                  color={color}
                  empty
                  size="large"
                />
              )}
            </div>
            <div className="pekelah-subtitle">{description}</div>
            <div className="pekelah-divider" />
            <div className="quantity-label">QUANTITY</div>

            {displayExactQuantities ? (
              <div className="quantity-btns">
                <span className="number-buttons">
                  <button
                    onClick={handleQuantityClicked(5)}
                    className={cn('quantity-btn', {
                      'btn-pressed': fivePressed,
                    })}
                  >
                    5
                  </button>
                  <button
                    onClick={handleQuantityClicked(10)}
                    className={cn('quantity-btn', {
                      'btn-pressed': tenPressed,
                    })}
                  >
                    10
                  </button>
                  <button
                    onClick={handleQuantityClicked(15)}
                    className={cn('quantity-btn', {
                      'btn-pressed': fifteenPressed,
                    })}
                  >
                    15
                  </button>
                  <button
                    onClick={handleQuantityClicked(20)}
                    className={cn('quantity-btn', {
                      'btn-pressed': twentyPressed,
                    })}
                  >
                    20
                  </button>
                  <button
                    onClick={handleQuantityClicked(25)}
                    className={cn('quantity-btn', {
                      'btn-pressed': twentyFivePressed,
                    })}
                  >
                    25
                  </button>
                </span>
                <button
                  onClick={(e) => {
                    handleRemoveClicked(e);
                    setDisplayExactQuanitites(false);
                  }}
                  className="other-quantity-btn"
                >
                  OTHER
                </button>
              </div>
            ) : (
              <div>
                <input
                  type="text"
                  value={currentQuantity}
                  className="other-quantity"
                  onChange={handleOtherQuantity}
                  onKeyPress={(e) => {
                    e.stopPropagation();
                    if (!/\d/.test(e.key)) {
                      e.preventDefault();
                    }
                  }}
                />
              </div>
            )}
          </div>
        </div>
        {Boolean(currentQuantity) && (
          <div className="pekelach-added">
            <div className="number-added">
              {currentQuantity} pekelach added to the cart
            </div>
            <button className="remove-btn" onClick={handleRemoveClicked}>
              <span>
                <Icon name="trash" />
              </span>
              <span>Remove</span>
            </button>
          </div>
        )}
      </a.div>
    </div>
  );
};

export default Pekelah;
