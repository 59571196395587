import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { REGISTER_ROUTE } from '../../routes';
import './ForgotPasswordModal.css';

const ForgotPasswordModal = ({ open, setOpen }) => {
  const history = useHistory();

  return (
    <Modal
      size="tiny"
      className="forgot-password-modal"
      closeIcon
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
    >
      <Header content="Forgot Username or Password?" />
      <Modal.Content>
        <p>
          To reset your password you may proceed to register again. Once you
          enter your email you'll have the option to enter a new password.
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="blue" onClick={() => setOpen(false)}>
          <Icon name="close" /> Close
        </Button>
        <Button primary onClick={() => history.push(REGISTER_ROUTE)}>
          Register
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default ForgotPasswordModal;
