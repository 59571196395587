import React, { useContext } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import { CartContext } from '../../contexts/CartProvider';
import './CartErrorModal.css';

const CartErrorModal = () => {
  const { cartErrorMessage, setCartErrorMessage } = useContext(CartContext);

  const reset = () => {
    setCartErrorMessage('');
  };

  return (
    <Modal
      size="small"
      className="cart-error-modal"
      closeIcon
      open={Boolean(cartErrorMessage)}
      onClose={reset}
    >
      <Header content="Checkout Error" />
      <Modal.Content>
        <div className="cart-error-text">{cartErrorMessage}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="blue" onClick={reset}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default CartErrorModal;
