import React from 'react';
import { Link } from 'react-router-dom';
import { SHALOCH_MANOS_ROUTE } from '../../routes';
import './NoMatch.css';

const NoMatch = (props) => (
  <div className="no-match">
    <h1>404 - Not Found</h1>
    <h2>
      This is not the correct page - try going{' '}
      <Link to={SHALOCH_MANOS_ROUTE}>here</Link> instead
    </h2>
  </div>
);

export default NoMatch;
