import { BACKEND_URL, JWT_KEY } from '../config';
import { getWithExpiry } from './localStorageService';

export const getStaffCategories = async () => {
  const response = await fetch(`${BACKEND_URL}api/v1/staffCategory`, {
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      'Accept-Encoding': 'gzip',
    },
  }).catch((errors) => {
    console.error('Error:', errors);
    return { errors };
  });

  if (!response.ok) {
    const errors = await response.text();
    console.log('Error in Staff Categories?: ', errors);
    return { errors };
  }

  const categories = await response.json();
  return { data: categories };
};
