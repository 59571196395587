import React from 'react';
import './Reciprocity.css';
import ReactSwitch from 'react-switch';

const Reciprocity = ({ reciprocityFlag, setReciprocityFlag }) => (
  <div className="reciprocity">
    <div className="reciprocity-title">Reciprocity</div>
    <div className="reciprocity-text">
      Turning on reciprocity will automatically ensure that anyone sending to
      you receives your recognition. Automatically add any names to your list if
      you have been added to theirs. A second charge for reciprocity will run at
      the close of the program with an email receipt.
    </div>
    <div className="reciprocity-divider" />

    <label className="reciprocity-switch">
      <ReactSwitch
        height={20}
        width={40}
        checked={reciprocityFlag}
        onChange={() => setReciprocityFlag(!reciprocityFlag)}
      />
      <span>Reciprocity is {reciprocityFlag ? 'On' : 'Off'}</span>
    </label>
  </div>
);

export default Reciprocity;
