import { BACKEND_URL, JWT_KEY } from '../config';
import { getWithExpiry } from './localStorageService';

export const payByCheck = async ({
  checkCustomer = true,
  clientSecret = null,
  itemOrderGroup,
  orderGroup,
  pricePerOrder,
  stripePaymentId,
  year,
}) => {
  const response = await fetch(`${BACKEND_URL}api/v1/payment/check`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
    body: JSON.stringify({
      checkCustomer,
      clientSecret,
      itemOrderGroup,
      orderGroup,
      pricePerOrder,
      stripePaymentId,
      year,
    }),
  }).catch((err) => {
    console.error(`Error paying by check:`, err);
  });

  if (!response.ok) {
    console.log(
      'ERROR in response (payment by check): ',
      await response.text()
    );
    throw new Error(response);
  }

  return await response.text();
};
