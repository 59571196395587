import React, { useContext, useState } from 'react';
import { Button, Form, Header, Modal, TextArea } from 'semantic-ui-react';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { OrganizationContext } from '../../contexts/OrganizationProvider';
import { sendHelpEmail } from '../../services/emailService';
import EZInput from '../EZInput/EZInput';
import './HelpModal.css';

const HelpModal = () => {
  const { showHelpModal: open, setShowHelpModal: setOpen } = useContext(
    AuthenticationContext
  );
  const { phone } = useContext(OrganizationContext);

  const [websiteHelpNeeded, setWebsiteHelpNeeded] = useState(false);
  const [fieldErrors, setFieldErrors] = useState({});
  const [subject, setSubject] = useState('');
  const [text, setText] = useState('');
  const handleClickHelpType = () => setWebsiteHelpNeeded(!websiteHelpNeeded);

  const handleChangeSubject = (e) => {
    const newValue = e.target.value;
    setFieldErrors({ ...fieldErrors, subject: newValue ? '' : 'Required' });
    setSubject(newValue);
  };

  const handleChangeDescription = (e) => {
    const newValue = e.target.value;
    setText(newValue);
  };

  const reset = () => {
    setOpen(false);
    setSubject('');
    setText('');
    setWebsiteHelpNeeded(false);
  };

  return (
    <Modal
      size="small"
      className="help-modal"
      closeIcon
      open={open}
      onClose={reset}
      onOpen={() => setOpen(true)}
    >
      <Header content="EZPurim Help" />
      <Modal.Content>
        <div className="call-us-help-text">
          Please, call us at {phone} or use the form below to contact us via
          email.
        </div>

        <div className="help-text">
          Please, select which of the following you need help with:
        </div>
        <Form>
          <div className="help-type-btn-container">
            <Button active={!websiteHelpNeeded} onClick={handleClickHelpType}>
              General Help
            </Button>
            <Button active={websiteHelpNeeded} onClick={handleClickHelpType}>
              Website Help
            </Button>
          </div>
          <EZInput
            errors={fieldErrors}
            className="subject-field"
            label="Subject"
            labelClassName="bold-label"
            name="subject"
            value={subject}
            handleValueChange={handleChangeSubject}
            inputProps={{ placeholder: 'What do you need help with?' }}
          />
          <div className="bold-label">Description</div>
          <TextArea
            value={text}
            onChange={handleChangeDescription}
            label="Description"
          />
        </Form>
        <div className="help-text bottom-help-text">
          We’ll reply via email in the order in which your message was received.
        </div>
      </Modal.Content>
      <Modal.Actions>
        <Button basic color="blue" onClick={reset}>
          Cancel
        </Button>
        <Button
          primary
          onClick={async () => {
            const messageType = websiteHelpNeeded ? 'SITE_HELP' : 'ORG_HELP';
            await sendHelpEmail({ subject, text, messageType });
            reset();
          }}
        >
          Send
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default HelpModal;
