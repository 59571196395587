const pullOutStaffCategory = (
  key,
  staffCategories,
  matchWordsArr,
  result,
  butNotArr
) => {
  const relevantCategories = staffCategories.filter(({ category }) => {
    const categoryLowerCase = category.toLowerCase();
    if (
      butNotArr &&
      butNotArr.some((matchWord) =>
        categoryLowerCase.includes(matchWord.toLowerCase())
      )
    ) {
      return false;
    }
    return matchWordsArr.some((matchWord) =>
      categoryLowerCase.includes(matchWord.toLowerCase())
    );
  });

  return { ...result, [key]: relevantCategories };
};

export const PRESCHOOL_STAFF = 'PRESCHOOL STAFF';
export const OPERATIONS = 'OPERATIONS';
export const OFFICE_STAFF = 'OFFICE STAFF';
export const REBBEIM = 'REBBEIM';
export const ADMINISTRATIVE_STAFF = 'ADMINISTRATIVE STAFF';
export const TEACHERS = 'TEACHERS';

export const groupStaffCategories = (staffCategories) => {
  let result = {};
  staffCategories.sort(({ category }, { category: theOtherCategory }) => {
    if (category < theOtherCategory) {
      return -1;
    }
    if (category > theOtherCategory) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  result = pullOutStaffCategory(
    PRESCHOOL_STAFF,
    staffCategories,
    ['Nursery', 'Kindergarten', 'Pre 1a', 'Preschool'],
    result
  );
  result = pullOutStaffCategory(
    OFFICE_STAFF,
    staffCategories,
    ['Executive', 'Educational', 'Hot Lunch', 'Babysitters'],
    result
  );
  result = pullOutStaffCategory(
    OPERATIONS,
    staffCategories,
    ['Maintenance', 'Guard'],
    result
  );
  result = pullOutStaffCategory(
    REBBEIM,
    staffCategories,
    ['Rebbeim', 'Hanhala'],
    result
  );
  result = pullOutStaffCategory(
    ADMINISTRATIVE_STAFF,
    staffCategories,
    ['Administrators'],
    result
  );
  result = pullOutStaffCategory(
    TEACHERS,
    staffCategories,
    ['Teachers', 'secular studies', 'kriah', 'drop in'],
    result,
    ['kindergarten', 'nursery']
  );

  return result;
};
