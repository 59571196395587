import React from 'react';
import { Table } from 'semantic-ui-react';
import CartLineItems from '../CartLineItems/CartLineItems';
import './ShalochManosCartContent.css';

const ShalochManosCartContent = ({ people, showAddress }) => {
  return (
    <div className="my-cart">
      <div className="line-items-table-container">
        <Table singleLine className="line-items-table">

          <CartLineItems showAddress={showAddress} people={people} />
        </Table>
      </div>
    </div>
  );
};

export default ShalochManosCartContent;
