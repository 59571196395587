import React, { useContext, useEffect } from 'react';
import { Table, Icon, Button } from 'semantic-ui-react';
import './PersonRow.css';
import Highlighter from 'react-highlight-words';
import cn from 'classnames';
import StaffCategoryBadges from './StaffCategoryBadges/StaffCategoryBadges';
import { CartContext, SHALOCH_CUSTOMER_IDS } from '../contexts/CartProvider';

const AddRemovePaidButton = ({ id, selections, setSelections, paidFor }) => {
  const { updateCart, cart } = useContext(CartContext);

  const handleSelect = (recordId, value) => {
    setSelections((prevSelections) => {
      const newSelections = {
        ...prevSelections,
        [recordId]: value,
      };

      return newSelections;
    });
  };

  useEffect(() => {
    const selectedIds = Object.entries(selections).reduce(
      (accum, [key, value]) => {
        if (value) {
          return accum.concat(Number.parseInt(key, 10));
        }

        return accum;
      },
      []
    );
    updateCart(SHALOCH_CUSTOMER_IDS, selectedIds);
  }, [selections]);

  if (paidFor) {
    return <div className="ordered-and-paid">ordered and paid</div>;
  }

  return cart[SHALOCH_CUSTOMER_IDS].includes(id) ? (
    <Button
      className="btn-remove-from-cart"
      onClick={() => handleSelect(id, false)}
      basic
      color="red"
    >
      Remove from Cart
    </Button>
  ) : (
    <Button
      style={{ marginTop: '0 !important' }}
      className="btn-add-to-cart"
      basic
      color="blue"
      onClick={() => handleSelect(id, true)}
    >
      Add to Cart
    </Button>
  );
};

const PersonRow = ({
  customerId,
  firstNames,
  lastName,
  title,
  addressLine1,
  searchTerm,
  selections,
  setSelections,
  staffCategoryIds,
  staffCategories,
}) => {
  const { paidForCustomers = [] } = useContext(CartContext);
  const paidFor = paidForCustomers.includes(customerId);
  const fullName = `${lastName}, ${title} ${firstNames ? firstNames : ''}`;

  const isItemSelected = (recordId) => selections[recordId];
  const labelName = (id) =>
    staffCategories.find(({ staffCategoryId }) => staffCategoryId === id)
      .category;

  return (
    <Table.Row
      className={cn('record-table-row', {
        selected: isItemSelected(customerId),
        'paid-for': paidFor,
      })}
    >
      <Table.Cell>
        <div className="add-remove-customer">
          <AddRemovePaidButton
            id={customerId}
            selections={selections}
            setSelections={setSelections}
            paidFor={paidFor}
          />
          <div className="name-address-content">
            <div className="name-inside-cell">
              <Highlighter
                highlightClassName="highlight"
                searchWords={searchTerm.split(' ')}
                autoEscape={true}
                textToHighlight={fullName}
              />
            </div>
            <div className="address-inside-cell">
              <Highlighter
                highlightClassName="highlight"
                searchWords={searchTerm.split(' ')}
                autoEscape={true}
                textToHighlight={addressLine1}
              />
            </div>
          </div>
          <div className="categories">
            {staffCategoryIds && (
              <StaffCategoryBadges
                labelName={labelName}
                staffCategoryIds={staffCategoryIds}
              />
            )}
          </div>
          {isItemSelected(customerId) && (
            <div className="green-check">
              <Icon
                className="green-check-icon"
                name="check circle"
                size="large"
              />
            </div>
          )}

          {paidFor && (
            <div className="paid-for-icon-section">
              <Icon className="paid-for-icon" name="lock" />
            </div>
          )}
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default PersonRow;
