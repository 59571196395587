import React from 'react';
import { Table } from 'semantic-ui-react';
import PersonRow from './PersonRow';
import './PeopleTable.css';

const PeopleTable = ({
  people,
  searchTerm,
  selections,
  setSelections,
  staffCategories,
}) => {
  return (
    <div className="people-table">
      <Table className="people-table" celled>
        <Table.Body>
          {people.map((person) => (
            <PersonRow
              {...person}
              key={person.customerId}
              searchTerm={searchTerm}
              selections={selections}
              setSelections={setSelections}
              staffCategories={staffCategories}
            />
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default PeopleTable;
