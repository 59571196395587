import React, { useEffect, useState } from 'react';
import Autosuggest from 'react-autosuggest';
import { BACKEND_URL } from '../../config';
import './NameAddressTypeahead.css';

const NameAddressTypeahead = ({ setCustomerId, email, pin }) => {
  const [people, setPeople] = useState([]);

  const getSuggestions = (value) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    if (inputLength < 2) {
      return [];
    }

    const filteredPeople = [];
    for (const person of people) {
      const { lastName, firstNames, fullAddress } = person;

      const fullNameAddress = `${lastName} ${firstNames} ${fullAddress}`;
      const searchTermParts = inputValue.split(' ');
      if (
        searchTermParts.every((searchTermPart) =>
          fullNameAddress
            .toLowerCase()
            .includes(searchTermPart.toLocaleLowerCase())
        )
      ) {
        filteredPeople.push(person);
      }

      if (filteredPeople.length === 100) break;
    }

    return filteredPeople;
  };

  const renderSuggestion = (suggestion) => (
    <div>{getSuggestionValue(suggestion)}</div>
  );

  const getSuggestionValue = ({ firstNames, title, lastName, noZipAddress }) =>
    `${title} ${firstNames} ${lastName} - ${noZipAddress}`;

  useEffect(() => {
    fetch(
      `${BACKEND_URL}api/v1/auth/search?email=${email}&orgId=2&pin=${pin}`,
      {
        headers: {
          'Content-Type': 'application/json',
          'Accept-Encoding': 'gzip',
        },
      }
    )
      .then((response) => response.json())
      .then((people) => {
        people = people
          .sort(({ lastName }, { lastName: otherLastName }) =>
            lastName.localeCompare(otherLastName)
          )
          .map((person) => {
            const address = person.fullAddress || '';
            const statePosition = address.trim().lastIndexOf(' ');
            return {
              ...person,
              noZipAddress: address.slice(0, statePosition),
            };
          });
        setPeople(people);
      })
      .catch((error) => console.log('Search for suggestion failed?', error));
  }, [email, pin]);

  const [value, setValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const onChange = (event, { newValue }) => {
    setValue(newValue);
    setCustomerId('');
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    setSuggestions(getSuggestions(value));
  };

  // Autosuggest will call this function every time you need to clear suggestions.
  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const inputProps = {
    placeholder: 'Search For Your Name or Address  Try: "Cohen Clarks"',
    value,
    onChange,
  };

  const onSuggestionSelected = (
    event,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }
  ) => {
    event.preventDefault();
    const { customerId } = suggestion;
    setCustomerId(customerId);
  };

  return (
    <Autosuggest
      suggestions={suggestions}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={inputProps}
    />
  );
};

export default NameAddressTypeahead;
