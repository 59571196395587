import React, { useContext, useEffect } from 'react';
import './PekelachOrderingDonePage.css';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { useHistory } from 'react-router-dom';
import { SHALOCH_MANOS_ROUTE } from '../../routes';

const PekelachOrderingDonePage = (props) => {
  const { setShowSubheader, setShowCart, greetingName } = useContext(
    AuthenticationContext
  );
  useEffect(() => {
    setShowCart(true);
    setShowSubheader(false);
  }, []);

  const history = useHistory();
  const gotoShalochManos = () => history.push(SHALOCH_MANOS_ROUTE);

  return (
    <div className="pekelach-ordering-done-page">
      <div className="pekelach-ordering-done-content">
        <div className="main-text">Welcome {greetingName},</div>
        <div className="second-line">
          Pekelach Ordering has ended for this year.
        </div>
        <button className="next-btn" onClick={gotoShalochManos}>
          Proceed to Order Mishloach Manos
        </button>
      </div>
    </div>
  );
};

export default PekelachOrderingDonePage;
