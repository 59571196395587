import React, { createContext, useContext, useEffect, useState } from 'react';
import { BACKEND_URL, JWT_KEY, PERSONAL_GREETINGS } from '../config';
import { getCart, updateCustomerCart } from '../services/cartService';
import { getItems } from '../services/itemService';
import { getWithExpiry, setWithExpiry } from '../services/localStorageService';
import { getPaidForCustomerIds } from '../services/orderService';
import { AuthenticationContext } from './AuthenticationProvider';
import { OrganizationContext } from '../contexts/OrganizationProvider';

export const CartContext = createContext();
// export const SHALOCH_MANOS_PRICE = activeYearPrice;
export const SHALOCH_CUSTOMER_IDS = 'shalochManosIds';

export const nonZeroPekalachKeys = (cart) => {
  const { items } = cart;
  if (!items) {
    return [];
  }

  return Object.entries(items).reduce((accum, [key, value]) => {
    if (value > 0) {
      return accum.concat(key);
    }

    return accum;
  }, []);
};

export const CartProvider = ({ children }) => {
  const { activeYearPrice } = useContext(OrganizationContext);

  const [price, setPrice] = useState(0);
  const [cart, setCart] = useState({});
  const [cartInitialized, setCartInitialized] = useState(false);
  const [personalGreetings, setPersonalGreetings] = useState(
    () => getWithExpiry(PERSONAL_GREETINGS) || {}
  );
  const [pekalachs, setPekalachs] = useState([]);
  const [paidForCustomers, setPaidForCustomers] = useState([]);
  const [pastRecipientIds, setPastRecipientIds] = useState([]);
  const [itemsInCart, setItemsInCart] = useState(0);
  const [numOfPekelachItems, setNumOfPekelachItems] = useState(0);
  const [numOfShalochManosItems, setNumOfShalochManosItems] = useState(0);
  const [people, setPeople] = useState([]);
  const [stripeObject, setStripeObject] = useState(null);
  const [stripeElements, setStripeElements] = useState(null);
  const [nameOnCard, setNameOnCard] = useState('');
  const [reciprocityFlag, setReciprocityFlag] = useState(false);
  const [defaultGreeting, setDefaultGreeting] = useState('');
  const [greetingText, setGreetingText] = useState('');
  const [greetingSuffix, setGreetingSuffix] = useState(0);
  const [greetingSuffixes, setGreetingSuffixes] = useState([]);

  const { authenticated } = useContext(AuthenticationContext);
  const [cartErrorMessage, setCartErrorMessage] = useState('');
  const [creditCardComplete, setCreditCardComplete] = useState(false);
  const [checkSelected, setCheckSelected] = useState(false);
  const [customerInfoFetched, setCustomerInfoFetched] = useState(false);
  const [orderInfo, setOrderInfo] = useState({});

  useEffect(() => {
    (async () => {
      if (authenticated) {
        const { data } = await getItems();
        setPekalachs(data);
      }
    })();
  }, [authenticated]);

  useEffect(() => {
    (async () => {
      if (authenticated) {
        const cart = await getCart();
        setCart(cart);
        setCartInitialized(true);
      }
    })();
  }, [authenticated]);

  useEffect(() => {
    (async () => {
      if (authenticated) {
        const thisYear = new Date().getFullYear();
        setPaidForCustomers(await getPaidForCustomerIds(thisYear));
      }
    })();
  }, [authenticated]);

  const updatePaidForCustomers = async () => {
    if (authenticated) {
      const thisYear = new Date().getFullYear();
      setPaidForCustomers(await getPaidForCustomerIds(thisYear));
    }
  };

  useEffect(() => {
    if (!authenticated) return () => {};

    const thisYear = new Date().getFullYear();
    fetch(`${BACKEND_URL}api/v1/customer/summary?year=${thisYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((people) => {
        setPeople(people);
      })
      .catch((error) => console.log('No way! ', error));
  }, [authenticated]);

  useEffect(() => {
    if (!authenticated || people.length === 0) return () => {};
    setPastRecipientIds(
      people
        .filter(({ pastRecipient }) => pastRecipient)
        .map(({ customerId }) => customerId)
    );
  }, [authenticated, people]);

  useEffect(() => {
    setPrice(activeYearPrice);
    const { items = {}, shalochManosIds = [] } = cart;
    const pekalachCount = Object.values(items).reduce(
      (accum, value) => accum + value,
      0
    );
    setItemsInCart(pekalachCount + shalochManosIds.length);

    if (!items) {
      setNumOfPekelachItems(0);
    } else {
      setNumOfPekelachItems(
        Object.entries(items).reduce((accum, [_, value]) => accum + value, 0)
      );
    }

    setNumOfShalochManosItems(
      cart[SHALOCH_CUSTOMER_IDS] ? cart[SHALOCH_CUSTOMER_IDS].length : 0
    );
  }, [cart]);

  useEffect(() => {
    if (authenticated && cartInitialized) {
      const syncCart = async () => {
        await updateCustomerCart(cart);
      };
      syncCart();
    }
  }, [cart, authenticated, cartInitialized]);

  useEffect(() => {
    setWithExpiry(PERSONAL_GREETINGS, personalGreetings, '604800000');
  }, [personalGreetings]);

  const updateCart = (id, numberOfItemsOrArray) => {
    if (id !== SHALOCH_CUSTOMER_IDS) {
      const { items = {} } = cart;
      items[id] = numberOfItemsOrArray;
      setCart({ ...cart, items: { ...items } });
      return;
    }

    setCart({ ...cart, [SHALOCH_CUSTOMER_IDS]: numberOfItemsOrArray });
  };

  const clearCart = () => {
    setCart({});
  };

  const updatePersonalGreetings = (id, personalGreeting) => {
    setPersonalGreetings({ ...personalGreetings, [id]: personalGreeting });
  };

  const value = {
    pekalachs,
    cart,
    updateCart,
    clearCart,
    cartInitialized,
    setCartInitialized,
    itemsInCart,
    setCart,
    numOfPekelachItems,
    numOfShalochManosItems,
    people,
    stripeObject,
    setStripeObject,
    stripeElements,
    setStripeElements,
    nameOnCard,
    setNameOnCard,
    reciprocityFlag,
    setReciprocityFlag,
    defaultGreeting,
    setDefaultGreeting,
    greetingText,
    setGreetingText,
    greetingSuffix,
    setGreetingSuffix,
    greetingSuffixes,
    setGreetingSuffixes,
    personalGreetings,
    updatePersonalGreetings,
    paidForCustomers,
    updatePaidForCustomers,
    cartErrorMessage,
    setCartErrorMessage,
    creditCardComplete,
    setCreditCardComplete,
    checkSelected,
    setCheckSelected,
    orderInfo,
    setOrderInfo,
    customerInfoFetched,
    setCustomerInfoFetched,
    pastRecipientIds,
    price
  };

  return (
    <CartContext.Provider value={{ ...value }}>{children}</CartContext.Provider>
  );
};

export default CartProvider;
