import React, { useContext, useEffect, useState } from 'react';
import './AccountInfoPage.css';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import ReviewInfoBox from '../../components/ReviewInfoBox/ReviewInfoBox';
import './AccountInfoPage.css';
import { getCustomerInfo } from '../../services/customerService';
import { getWithExpiry } from '../../services/localStorageService';
import { CUSTOMER_ID } from '../../config';

const AccountInfoPage = (props) => {
  const { setShowSubheader } = useContext(AuthenticationContext);
  const [accountInfo, setAccountInfo] = useState({});

  const getCustomer = async (e) => {
    const accountInfo = await getCustomerInfo(getWithExpiry(CUSTOMER_ID));
    setAccountInfo(accountInfo)
  }

  useEffect(() => {
    setShowSubheader(false);
    getCustomer();
  }, [setShowSubheader]);

  return (
    <div className="account-info-page">
      <h1>Account Information</h1>
      <ReviewInfoBox
        accountInfo={accountInfo}
        email={accountInfo.email}
        />
    </div>
  );
};

export default AccountInfoPage;
