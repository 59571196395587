import React, { createContext, useState } from 'react';
import { JWT_KEY, USER_FULLNAME_KEY, USER_LASTNAME_KEY } from '../config';
import { getWithExpiry } from '../services/localStorageService';
import jwt_decode from 'jwt-decode';

export const AuthenticationContext = createContext();

//TODO - There is already a similar method - ideally, we would refactor
// it to be the same one instead of duplicating code...
const isJwtValidLocal = () => {
  const accessToken = getWithExpiry(JWT_KEY);
  if (!accessToken) {
    return false;
  }

  let decodedAccessToken = jwt_decode(accessToken);
  const nowInMillis = new Date().getTime();
  const jwtExpirationInMillis = decodedAccessToken.exp * 1000;
  if (nowInMillis < jwtExpirationInMillis) {
    return true;
  }

  return false;
};

const AuthenticationProvider = ({ children }) => {
  const [greetingName, setGreetingName] = useState(
    getWithExpiry(USER_FULLNAME_KEY)
  );

  const [lastName, setLastName] = useState(getWithExpiry(USER_LASTNAME_KEY));
  const [phoneNumber, setPhonenumber] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [showCart, setShowCart] = useState(false);
  const [showSubheader, setShowSubheader] = useState(false);
  const [showHelpModal, setShowHelpModal] = useState(false);
  const [authenticated, setAuthenticated] = useState(isJwtValidLocal());

  const value = {
    subtitle,
    setSubtitle,
    greetingName,
    setGreetingName,
    showCart,
    setShowCart,
    showSubheader,
    setShowSubheader,
    showHelpModal,
    setShowHelpModal,
    lastName,
    setLastName,
    authenticated,
    setAuthenticated,
    phoneNumber,
    setPhonenumber,
  };

  return (
    <AuthenticationContext.Provider value={{ ...value }}>
      {children}
    </AuthenticationContext.Provider>
  );
};

export default AuthenticationProvider;
