import jwt_decode from 'jwt-decode';
import { useContext } from 'react';
import { AuthenticationContext } from '../contexts/AuthenticationProvider';
import { JWT_KEY, USER_PHONE_NUMBER } from '../config';
import { getWithExpiry } from '../services/localStorageService';

export const useJwtToken = () => {
  const { setAuthenticated, setPhonenumber } = useContext(AuthenticationContext);

  const isJwtValid = () => {
    const accessToken = getWithExpiry(JWT_KEY);
    const phoneNumber = getWithExpiry(USER_PHONE_NUMBER)
    if (!accessToken) {
      return false;
    }

    let decodedAccessToken = jwt_decode(accessToken);
    const nowInMillis = new Date().getTime();
    const jwtExpirationInMillis = decodedAccessToken.exp * 1000;
    if (nowInMillis < jwtExpirationInMillis) {
      setAuthenticated(true);
      setPhonenumber(phoneNumber);
      return true;
    }

    setAuthenticated(false);
    return false;
  };

  return { isJwtValid };
};
