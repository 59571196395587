import React from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import './EmptyCart.css';

const EmptyCart = ({ name, secondLine, gotoPage, btnText = name }) => {
  const history = useHistory();
  return (
    <div className="empty-cart">
      <Icon name="gift" size="huge" color="grey"/>
      <div className="empty-cart-text">
        Your {name} cart is empty. <br />
        {secondLine}
      </div>
      <button className="add-stuff-btn" onClick={() => history.push(gotoPage)}>
        Add {btnText}
      </button>
    </div>
  );
};

export default EmptyCart;
