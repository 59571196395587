import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../../contexts/CartProvider';
import CartDefaultGreeting from '../CartDefaultGreeting/CartDefaultGreeting';
import EmptyCart from '../EmptyCart/EmptyCart';
import ShalochManosCartContent from '../ShalochManosCartContent/ShalochManosCartContent';
import Reciprocity from '../Reciprocity/Reciprocity';
import './ShalochManosCart.css';
import { Icon } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';
import { getWithExpiry } from '../../services/localStorageService';
import { getCustomerInfo } from '../../services/customerService';
import { CUSTOMER_ID } from '../../config';
import { SHALOCH_MANOS_ROUTE } from '../../routes';
import { OrganizationContext } from '../../contexts/OrganizationProvider';

const ShalochManosCart = ({ people }) => {
  const { defaultGreeting: orgDefaultGreeting } =
    useContext(OrganizationContext);

  const {
    numOfShalochManosItems,
    numOfPekelachItems,
    reciprocityFlag,
    setReciprocityFlag,
    setDefaultGreeting,
    setGreetingSuffix,
    greetingSuffixes,
    setGreetingSuffixes,
    customerInfoFetched,
    setCustomerInfoFetched,
  } = useContext(CartContext);
  const [showAddress, setShowAddress] = useState(false);
  const handleShowAddresses = () => setShowAddress(!showAddress);
  const history = useHistory();

  useEffect(() => {
    if (customerInfoFetched) {
      return () => {};
    }

    (async () => {
      const {
        reciprocation,
        defaultGreeting,
        greetingSuffix,
        greetingSuffixes,
      } = await getCustomerInfo(getWithExpiry(CUSTOMER_ID));

      // Cannot have a blank default greeting
      const sanitizedDefaultGreeting = defaultGreeting
        ? defaultGreeting
        : orgDefaultGreeting;
      setReciprocityFlag(reciprocation);
      setDefaultGreeting(sanitizedDefaultGreeting);
      setGreetingSuffix(greetingSuffix);
      setGreetingSuffixes(greetingSuffixes);
      setCustomerInfoFetched(true);
    })();
  }, []);

  if (greetingSuffixes.length < 2) {
    return 'Loading...';
  }

  return (
    <div className="shaloch-manos-cart">
      {numOfShalochManosItems > 0 && (
        <div
          className="more-recipients"
          onClick={() => history.push(SHALOCH_MANOS_ROUTE)}
        >
          <Icon name="angle left" />
          <span>Add or Remove Recipients</span>
        </div>
      )}

      <div className="top-cards">
        {(numOfShalochManosItems > 0 ||
          numOfPekelachItems > 0 ||
          reciprocityFlag) && (
          <Reciprocity
            reciprocityFlag={reciprocityFlag}
            setReciprocityFlag={setReciprocityFlag}
          />
        )}
        {numOfShalochManosItems > 0 && <CartDefaultGreeting />}
      </div>

      <div className="cart-title">
        My Mishloach Manos Cart ({numOfShalochManosItems})
        {numOfShalochManosItems > 0 && (
          <button
            className="link-action show-address-btn"
            onClick={handleShowAddresses}
          >
            {showAddress ? 'Hide Addresses' : 'Show Addresses'}
          </button>
        )}
      </div>
      <div className="shaloch-manos-cart-content">
        {numOfShalochManosItems === 0 && (
          <EmptyCart
            name="Mishloach Manos"
            secondLine="Take advantage of this beautiful opportunity!"
            gotoPage={SHALOCH_MANOS_ROUTE}
            btnText="Names"
          />
        )}

        {numOfShalochManosItems > 0 && (
          <ShalochManosCartContent people={people} showAddress={showAddress} />
        )}
      </div>
    </div>
  );
};

export default ShalochManosCart;
