import React from 'react';
import './ButtonLink.css';

const ButtonLink = ({ children, handleClick = () => {} }) => (
  <button onClick={handleClick} className="button-link">
    {children}
  </button>
);

export default ButtonLink;
