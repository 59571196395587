import {
  SHALOCH_CUSTOMER_IDS,
  // SHALOCH_MANOS_PRICE,
} from '../../contexts/CartProvider';

export const removePekalach = (name) => name.replace(/pekelach/i, '');

export const calculateTotal = (cart, pekalachs, price) => {
  if (!Object.keys(cart).length) {
    return 0;
  }

  const shalochManosItems = cart[SHALOCH_CUSTOMER_IDS].length;
  const shalochManosTotal = shalochManosItems * price;

  const pekalachTotal = pekalachs.reduce((accum, { itemId, price }) => {
    const currentTotal = (cart.items[itemId] || 0) * price;
    return accum + currentTotal;
  }, 0);

  return shalochManosTotal + pekalachTotal;
};

export const calculateManosTotal = (cart, price) => {
  const shalochManosItems = cart[SHALOCH_CUSTOMER_IDS].length;
  return shalochManosItems * price;
};

export const calculatePekalachTotal = (cart, pekalachs) => {
  return pekalachs.reduce((accum, { itemId, price }) => {
    const currentTotal = cart.items[itemId] * price;
    return accum + currentTotal;
  }, 0);
};

export const plularize = (item, quantity) =>
  quantity > 1 ? `${item}s` : `${item}`;

export const addItemOrders = (result, cart, pekalachs) => {
  const itemOrders = pekalachs.reduce((accum, { itemId, price }) => {
    const quantity = cart.items[itemId];
    if (quantity > 0) {
      accum.push({ itemId, price, quantity });
    }

    return accum;
  }, []);

  result.itemOrders = itemOrders;

  return result;
};
