import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { CartContext } from '../../contexts/CartProvider';
import { REVIEW_CART_ROUTE } from '../../routes';
import './HeaderCart.css';

const HeaderCart = (props) => {
  const history = useHistory();
  const { showCart } = useContext(AuthenticationContext);
  const { itemsInCart } = useContext(CartContext);

  if (!showCart) return null;

  return (
    <div>
      <button
        className="cart-btn"
        onClick={() => history.push(REVIEW_CART_ROUTE)}
      >
        <Icon name="cart" className="cart-icon"/>
        <span>
        <span className="cart-text">
          cart{' '} </span>
          {Boolean(itemsInCart) && (
            <span className="quantity">({itemsInCart})</span>
          )}
        </span>
      </button>
    </div>
  );
};

export default HeaderCart;
