import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import './CheckboxGenerator.css';

const CheckboxGenerator = ({
  groups,
  label,
  toggleStaffCategorySelection = () => {},
  checkboxValues = {},
}) => {
  const groupCheckboxes = groups[label];

  return (
    <div className="checkbox-generator">
      <h5>{label}</h5>
      {groupCheckboxes.map(({ category, staffCategoryId }) => (
        <Checkbox
          checked={checkboxValues[staffCategoryId]}
          className="staff-category-checkbox"
          key={staffCategoryId}
          label={category}
          onChange={() => toggleStaffCategorySelection(staffCategoryId)}
        />
      ))}
    </div>
  );
};

export default CheckboxGenerator;
