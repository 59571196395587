import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import './ConfirmationPage.css';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { CartContext } from '../../contexts/CartProvider';
import { LANDING_ROUTE } from '../../routes';
import { OrganizationContext } from '../../contexts/OrganizationProvider';

const ConfirmationPage = (props) => {
  const history = useHistory();
  const { setShowSubheader, setShowCart } = useContext(AuthenticationContext);
  const { updatePaidForCustomers, clearCart, numOfPekelachItems } =
    useContext(CartContext);
  const { pickupMessage } = useContext(OrganizationContext);

  const pekelachExistedInCart = useMemo(() => numOfPekelachItems > 0, []);

  useEffect(() => {
    setShowCart(false);
    setShowSubheader(false);
    clearCart();
  }, []);

  useEffect(() => {
    return () => {
      if (history.action === 'POP') {
        updatePaidForCustomers();
        history.push(LANDING_ROUTE);
      }
    };
  }, [history]);

  return (
    <div className="confirmation-page">
      <div className="confirmation-order-content">
        <div className="top"></div>
        <div className="main-text">Thank you, your order has been placed.</div>
        <div className="second-line">
          Please check your email for order confirmation and detailed
          information.
        </div>

        {pekelachExistedInCart && (
          <div className="pickup-msg-confirmation">{pickupMessage}</div>
        )}
      </div>
    </div>
  );
};

export default ConfirmationPage;
