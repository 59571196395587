import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import EZBreadCrumb from '../../components/EZBreadCrumb/EZBreadCrumb';
import Pekelah from '../../components/Pekelah/Pekelah';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { CartContext } from '../../contexts/CartProvider';
import { SHALOCH_MANOS_ROUTE } from '../../routes';
import './PekelachPage.css';

const PekelachPage = (props) => {
  const { setSubtitle, setShowCart, setShowSubheader } = useContext(
    AuthenticationContext
  );

  const [allPekalachSoldOut, setAllPekalachSoldOut] = useState(false);

  const { pekalachs } = useContext(CartContext);

  useEffect(() => {
    setShowCart(true);
    setShowSubheader(true);
    setSubtitle(
      'Take advantage of our all new beautiful and time saving pre-packaged pekelach!'
    );
  }, []);

  useEffect(() => {
    setAllPekalachSoldOut(pekalachs.every(({ soldOut }) => soldOut));
  }, [pekalachs]);

  const history = useHistory();
  const gotoShalochManos = () => history.push(SHALOCH_MANOS_ROUTE);
  return (
    <>
      <div className="pekelach-page">
        <div className="progress-nav">
          <EZBreadCrumb />
        </div>
        <div className="nav-btns">
          <button className="link-action" onClick={gotoShalochManos}>
            Skip to Mishloach Manos recipients <Icon name="angle right" />
          </button>
        </div>
        <div className="pekelah-grid">
          {allPekalachSoldOut && (
            <div className="pekelach-sold-out">
              {/* All the pekelach are sold out for this year! */}
              All pekalach available at Market Maven. Limited quantities available. Please, proceed to
              Mishloach Manos Page
            </div>
          )}
          {pekalachs &&
            pekalachs
              .filter(({ soldOut }) => !Boolean(soldOut))
              .sort(({ price }, { price: other_price }) => price - other_price)
              .map((pekelah) => <Pekelah key={pekelah.itemId} {...pekelah} />)}
        </div>

        <button className="next-btn" onClick={gotoShalochManos}>
          Next: Mishloach Manos
        </button>
      </div>
    </>
  );
};

export default PekelachPage;
