import React, { useState, useContext } from 'react';
import './SearchCustomerAndRegister.css';
import NameAddressTypeahead from '../NameAddressTypeahead/NameAddressTypeahead';
import { registerCustomerService } from '../../services/registration';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { REGISTRATION_STEPS } from '../../pages/RegistrationPage/RegistrationPage';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { invisibleLogin } from '../../services/loginService';
import { getCustomerInfo } from '../../services/customerService';
import ButtonLink from '../ButtonLink/ButtonLink';

const SearchCustomerAndRegister = ({
  setRegistrationStep,
  appEmail: email,
  setAppEmail,
  setAccountInfo,
  appPin: pin,
  appPassword: newPassword,
}) => {
  const [customerId, setCustomerId] = useState('');
  const [verifying, setVerifying] = useState(false);
  const { setAuthenticated, setGreetingName, setLastName, setPhonenumber } = useContext(
    AuthenticationContext
  );

  const registerCustomer = async (e) => {
    e.preventDefault();

    setVerifying(true);

    const responseStatus = await registerCustomerService({
      email,
      newPassword,
      customerId,
    });

    setTimeout(async () => {
      if (!responseStatus) {
        setVerifying(false);
        return;
      }

      // No errors - login
      const loginStatus = await invisibleLogin({
        email,
        password: newPassword,
        setAuthenticated,
        setGreetingName,
        setLastName,
        setPhonenumber
      });
      if (loginStatus) {
        const accountInfo = await getCustomerInfo(customerId);
        setAccountInfo(accountInfo);
        setRegistrationStep(REGISTRATION_STEPS.REVIEW_CUSTOMER_INFO);
      } else {
        setVerifying(false);
      }
    }, 1500);
  };

  const goToNewCustomer = (e) => {
    e.preventDefault();
    setRegistrationStep(REGISTRATION_STEPS.NEW_CUSTOMER_INFO);
  };

  return (
    <div className="search-customer-box">
      <div className="search-customer-content-box">
        <div className="search-customer-content-title">
          Register to Order Online
        </div>
        <div className="important-message">
          {email} is not linked to an account.
        </div>
        <div className="action-message">
          Please search for your information to link it with your email address.
        </div>

        <form onSubmit={registerCustomer}>
          <div className="name-address-container">
            <NameAddressTypeahead
              email={email}
              pin={pin}
              setCustomerId={setCustomerId}
            />
          </div>
          <button type="submit" disabled={!customerId} className="email">
            {verifying ? (
              <div style={{ marginTop: '-10px', marginLeft: '20px' }}>
                <PacmanLoader size={10} loading={true} color={'white'} />
              </div>
            ) : (
              <span>Link My Email</span>
            )}
          </button>

          <div className="did-not-find-div">
            <ButtonLink handleClick={goToNewCustomer}>
              I couldn't find my name
            </ButtonLink>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SearchCustomerAndRegister;
