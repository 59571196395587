import React from 'react';
import './ContingencyPage.css';

const ContingencyPage = (props) => {
  return (
    <div className="contingency-page">
      <h1>Thank you for visiting EZPurim!</h1>

      <h2>
        We are so excited to help you with all of your Shaloch Manos needs!
      </h2>

      <h2>Check Back Soon!</h2>
    </div>
  );
};

export default ContingencyPage;
