import { BACKEND_URL, JWT_KEY } from '../config';
import { getWithExpiry } from './localStorageService';

export const sendHelpEmail = async ({ messageType, subject, text }) => {
  const response = await fetch(`${BACKEND_URL}api/v1/email/help`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },

    body: JSON.stringify({
      messageType,
      subject,
      text,
    }),
  }).catch((err) => {
    console.error(`Error sendHelpEmail:`, err);
  });

  if (!response.ok) {
    console.log('ERROR in response (sendHelpEmail): ', await response.text());
    throw new Error(response);
  }

  const result = await response.text();
  return { result };
};
