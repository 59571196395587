import React, { useContext, useState } from 'react';
import {
  Button,
  Form,
  Header,
  Label,
  Modal,
  TextArea,
} from 'semantic-ui-react';
import { CartContext } from '../../contexts/CartProvider';
import './EditGreetingModal.css';

const EditGreetingModal = ({ open, toggleOpen }) => {
  const {
    defaultGreeting,
    setDefaultGreeting,
    greetingSuffix,
    greetingSuffixes,
    setGreetingSuffix,
  } = useContext(CartContext);

  const [textInModal, setTextInModal] = useState(defaultGreeting || '');
  const [suffixInModal, setSuffixInModal] = useState(greetingSuffix);

  const greetingSuffix0 = greetingSuffixes[0];
  const greetingSuffix1 = greetingSuffixes[1];
  const largestSuffixLength = Math.max(
    greetingSuffix0.length,
    greetingSuffix1.length
  );

  const handleGreetingSuffixSelection = (index) => (e) =>
    setSuffixInModal(index);

  const resetThenClose = () => {
    setSuffixInModal(greetingSuffix);
    setTextInModal(defaultGreeting);
    toggleOpen();
  };

  return (
    <Modal
      size="small"
      className="help-modal"
      closeIcon
      open={open}
      onClose={resetThenClose}
      onOpen={toggleOpen}
    >
      <Header content="Edit Greeting" />
      <Modal.Content>
        <div className="cart-default-greeting-text">
          This greeting will be used for all your recipients.
        </div>

        <Form>
          <TextArea
            className="cart-default-greeting-text-area"
            rows={6}
            placeholder="Type your greeting here..."
            value={textInModal}
            onChange={(e) => {
              let newText = e.target.value;
              // eslint-disable-next-line no-control-regex
              newText = newText.replace(/[^\x00-\x7F]/g, '').slice(0, 100);
              setTextInModal(newText);
            }}
          />
          {textInModal.length > 0 && (
            <div>
              {100 - (textInModal.length + largestSuffixLength)} characters left
            </div>
          )}

          {textInModal.length === 0 && (
            <Label basic color="red" pointing>
              Greeting value required!
            </Label>
          )}

          <div id="greeting-suffixes">
            {greetingSuffixes.map((suffix, index) => (
              <div key={suffix}>
                <label className="greeting-suffix-radio">
                  <input
                    type="radio"
                    name="greetingSuffix"
                    checked={suffixInModal === index}
                    value={index}
                    onChange={handleGreetingSuffixSelection(index)}
                  />

                  <span className="label-greeting-suffix">
                    {greetingSuffixes[index]}
                  </span>
                </label>
              </div>
            ))}
          </div>
        </Form>
      </Modal.Content>
      <Modal.Actions>
        <Button
          disabled={textInModal.length === 0}
          primary
          onClick={() => {
            setDefaultGreeting(textInModal);
            setGreetingSuffix(suffixInModal);
            toggleOpen();
          }}
        >
          Save Greeting
        </Button>

        <Button basic color="blue" onClick={resetThenClose}>
          Cancel
        </Button>
      </Modal.Actions>
    </Modal>
  );
};
export default EditGreetingModal;
