import React, { useState, useEffect, useRef, useContext } from 'react';
import PeopleTable from './PeopleTable';
import { Input, Icon, Button, Label } from 'semantic-ui-react';
import './SearchScreen.css';
import { useDebouncedValue } from '../hooks/useDebouncedValue';
import SearchFieldIcon from './SearchFieldIcon';
import { getWithExpiry } from '../services/localStorageService';
import { useHistory, useLocation } from 'react-router-dom';
import { BACKEND_URL, JWT_KEY, PEKELACH_ORDERING_DONE } from '../config';
import SpringIn from './SpringIn/SpringIn';
import { getStaffCategories } from '../services/staffCategoriesService';
import {
  ADMINISTRATIVE_STAFF,
  groupStaffCategories,
  OFFICE_STAFF,
  OPERATIONS,
  PRESCHOOL_STAFF,
  REBBEIM,
  TEACHERS,
} from '../services/staffCategoriesUtil';
import CheckboxGenerator from './CheckboxGenerator/CheckboxGenerator';
import LabelGenerator from './LabelGenerator/LabelGenerator';
import { AuthenticationContext } from '../contexts/AuthenticationProvider';
import EZBreadCrumb from './EZBreadCrumb/EZBreadCrumb';
import { CartContext, SHALOCH_CUSTOMER_IDS } from '../contexts/CartProvider';
import { PEKELACH_ROUTE, REVIEW_CART_ROUTE } from '../routes';
import cn from 'classnames';

const PPL_TO_DISPLAY = 25;

const totalPages = (records) => Math.ceil(records / PPL_TO_DISPLAY) || 1;

const SearchScreen = () => {
  const { setSubtitle, setShowCart, setShowSubheader } = useContext(
    AuthenticationContext
  );
  const { updatePaidForCustomers } = useContext(CartContext);

  useEffect(() => {
    setSubtitle('Please search and add your mishloach manos recipients.');
    setShowCart(true);
    setShowSubheader(true);
    updatePaidForCustomers();
  }, []);

  const { cart, pastRecipientIds, paidForCustomers, updateCart } =
    useContext(CartContext);
  const history = useHistory();
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [people, setPeople] = useState(
    (location && location.state && location.state.people) || []
  );
  const [filteredPeople, setFilteredPeople] = useState([]);
  const [displayedPeople, setDisplayedPeople] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selections, setSelections] = useState(() =>
    (cart[SHALOCH_CUSTOMER_IDS] || []).reduce((accum, curr) => {
      accum[curr] = true;
      return accum;
    }, {})
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [gavePreviouslyFilter, setGavePreviouslyFilter] = useState(false);
  const [showStaffPanel, setShowStaffPanel] = useState(false);
  const [pastRecipientNumber, setPastRecipientNumber] = useState(0);

  const [staffCategories, setStaffCategories] = useState([]);
  const [staffCategoryGroups, setStaffCategoryGroups] = useState({});
  const [staffCategoryCheckboxValues, setStaffCategoryCheckboxValues] =
    useState({});
  const [staffCategoryFilter, setStaffCategoryFilter] = useState(false);

  const toggleStaffCategorySelection = (id) => {
    setStaffCategoryCheckboxValues({
      ...staffCategoryCheckboxValues,
      [id]: !staffCategoryCheckboxValues[id],
    });
  };

  const clearStaffCategorySelection = () => {
    const newValues = { ...staffCategoryCheckboxValues };
    const keys = Object.keys(newValues);
    keys.forEach((key) => (newValues[key] = false));
    setStaffCategoryCheckboxValues(newValues);
  };

  const handleSearchTermChange = (event) => setSearchTerm(event.target.value);
  const debouncedSearchTerm = useDebouncedValue(searchTerm);

  const handleAddAllPrevRecipients = () => {
    const pastRecipientsMinusPaid = pastRecipientIds.filter(
      (customerId) => !paidForCustomers.includes(customerId)
    );
    const prevSelectedIds = Object.entries(selections).reduce(
      (accum, [key, value]) => {
        if (value) {
          return accum.concat(Number.parseInt(key, 10));
        }

        return accum;
      },
      []
    );
    const mergedSelectedIds = [
      ...new Set(prevSelectedIds.concat(pastRecipientsMinusPaid)),
    ];
    mergedSelectedIds.forEach((recordId) => {
      setSelections((prevSelections) => {
        const newSelections = {
          ...prevSelections,
          [recordId]: true,
        };

        return newSelections;
      });
    });

    updateCart(SHALOCH_CUSTOMER_IDS, mergedSelectedIds);
  };

  useEffect(() => {
    (async () => {
      const { data: staffCategories } = await getStaffCategories();
      setStaffCategories(staffCategories);
    })();
  }, []);

  useEffect(() => {
    const staffCategoryGroups = groupStaffCategories(staffCategories);
    setStaffCategoryGroups(staffCategoryGroups);
    setStaffCategoryCheckboxValues(
      staffCategories.reduce((accum, { staffCategoryId }) => {
        accum[staffCategoryId] = false;
        return accum;
      }, {})
    );
  }, [staffCategories]);

  useEffect(() => {
    const labelIds = Object.entries(staffCategoryCheckboxValues).reduce(
      (accum, curr) => (curr[1] ? accum.concat(curr[0]) : accum),
      []
    );
    const staffCategorySelected = Boolean(labelIds.length);
    if (staffCategorySelected) {
      setGavePreviouslyFilter(false);
    }
    setStaffCategoryFilter(staffCategorySelected);
  }, [staffCategoryCheckboxValues]);

  useEffect(() => {
    setIsLoading(true);
    if (people.length > 0) {
      setFilteredPeople(people);
      setDisplayedPeople(people.slice(0, PPL_TO_DISPLAY));
      setPastRecipientNumber(
        people.filter(({ pastRecipient }) => pastRecipient).length
      );
      setIsLoading(false);
      return () => {};
    }

    setIsLoading(true);

    const thisYear = new Date().getFullYear();
    fetch(`${BACKEND_URL}api/v1/customer/summary?year=${thisYear}`, {
      headers: {
        'Content-Type': 'application/json',
        'Accept-Encoding': 'gzip',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
    })
      .then((response) => response.json())
      .then((people) => {
        people.sort(({ lastName }, { lastName: otherLastName }) =>
          lastName.localeCompare(otherLastName)
        );
        setPeople(people);
        setFilteredPeople(people);
        setDisplayedPeople(people.slice(0, PPL_TO_DISPLAY));
        setPastRecipientNumber(
          people.filter(({ pastRecipient }) => pastRecipient).length
        );
        setIsLoading(false);
      })
      .catch((error) => console.log('Error fetching customers? ', error));
  }, []);

  useEffect(() => {
    setCurrentPage(1);

    const staffCategoryFilterArray = Object.entries(staffCategoryCheckboxValues)
      .reduce((accum, curr) => (curr[1] ? accum.concat(curr[0]) : accum), [])
      .map((val) => parseInt(val));

    const filtered = people.filter(
      ({
        firstNames,
        lastName,
        title,
        addressLine1,
        pastRecipient,
        staffCategoryIds,
      }) => {
        if (staffCategoryFilterArray.length > 0) {
          if (!staffCategoryIds) {
            return false;
          }

          if (
            staffCategoryFilterArray.filter((x) => staffCategoryIds.includes(x))
              .length === 0
          )
            return false;
        }

        if (gavePreviouslyFilter && !pastRecipient) {
          return false;
        }

        const fullNameAddress = `${lastName} ${title} ${firstNames} ${addressLine1}`;
        if (searchTerm) {
          const searchTermParts = searchTerm.split(' ');
          return searchTermParts.every((searchTermPart) =>
            fullNameAddress
              .toLowerCase()
              .includes(searchTermPart.toLocaleLowerCase())
          );
        }

        return true;
      }
    );
    setFilteredPeople(filtered);
    setDisplayedPeople(filtered.slice(0, PPL_TO_DISPLAY));
  }, [debouncedSearchTerm, gavePreviouslyFilter, staffCategoryCheckboxValues]);

  useEffect(() => {
    const offset = (currentPage - 1) * PPL_TO_DISPLAY;
    setDisplayedPeople(
      filteredPeople.slice(0 + offset, PPL_TO_DISPLAY + offset)
    );
  }, [currentPage, filteredPeople]);

  useEffect(() => window.scrollTo(0, 0), [currentPage]);

  const lastPage = totalPages(filteredPeople.length);
  const searchInputEl = useRef(null);
  const goToCartPage = () => history.push(REVIEW_CART_ROUTE, { people });
  const goToPekelachPage = () => history.push(PEKELACH_ROUTE, { people });

  return (
    <div className="shaloch-manos-page">
      <div className="shaloch-manos-navigation-bar">
        {/* {PEKELACH_ORDERING_DONE || (
          <button className="link-action" onClick={goToPekelachPage}>
            <Icon name="angle left" /> Back To Pekelach
          </button>
        )} */}
        <EZBreadCrumb />
        {/* <button
          className={cn('link-action', {
            'lonely-cart': PEKELACH_ORDERING_DONE,
          })}
          onClick={goToCartPage}
        >
          Cart <Icon name="angle right" />
        </button> */}
      </div>
      <div className="search-screen">
        <div></div>
        <div>
          <div className="search-area-with-match-display">
            <div>
              <Input
                className="search-field"
                icon={<Icon name="search" />}
                placeholder='Search for names and addresses. Try "Horowitz Labyrinth"'
                value={searchTerm}
                onChange={handleSearchTermChange}
              >
                <input ref={searchInputEl} />
                <SearchFieldIcon
                  searchValue={searchTerm}
                  setSearchValue={setSearchTerm}
                  searchRef={searchInputEl}
                />
              </Input>
            </div>

            {(searchTerm || gavePreviouslyFilter || staffCategoryFilter) &&
              !isLoading &&
              filteredPeople.length !== people.length && (
                <div className="search-results-text">
                  Displaying {filteredPeople.length} search results
                  {(gavePreviouslyFilter || staffCategoryFilter) && (
                    <span
                      className="link-clear-filter"
                      onClick={() => {
                        setGavePreviouslyFilter(false);
                        clearStaffCategorySelection();
                      }}
                    >
                      Clear Filters
                    </span>
                  )}
                </div>
              )}

            <div className="search-filters-container">
              <div className="filter-staff">
                <Button
                  className="btn-filter-staff"
                  basic
                  color="blue"
                  active={staffCategoryFilter}
                  onClick={() => {
                    setShowStaffPanel(!showStaffPanel);
                  }}
                >
                  <span className="filter-staff-text">Filter Staff</span>
                  {showStaffPanel ? (
                    <Icon name="angle up" />
                  ) : (
                    <Icon name="angle down" />
                  )}
                </Button>
              </div>

              {Boolean(pastRecipientNumber) && (
                <div className="gave-to-filter">
                  <Button
                    className="btn-gave-in-the-past"
                    basic
                    color="blue"
                    toggle
                    active={gavePreviouslyFilter}
                    onClick={() => {
                      setSearchTerm('');
                      if (!gavePreviouslyFilter) {
                        clearStaffCategorySelection();
                      }
                      setGavePreviouslyFilter(!gavePreviouslyFilter);
                    }}
                  >
                    {gavePreviouslyFilter ? (
                      <Icon name="check" />
                    ) : (
                      <Icon name="users" />
                    )}
                    <span className="past-recipients">
                      View {pastRecipientNumber} past recipients
                    </span>
                  </Button>
                </div>
              )}
            </div>

            {showStaffPanel && Object.keys(staffCategoryGroups).length > 0 && (
              <SpringIn>
                <div className="filters-panel">
                  <div className="close-filters">
                    <span
                      className="link-clear-filter"
                      onClick={() => {
                        setShowStaffPanel(false);
                      }}
                    >
                      <Icon name="close" />
                      Close Filters
                    </span>
                  </div>
                  <div>
                    <CheckboxGenerator
                      toggleStaffCategorySelection={
                        toggleStaffCategorySelection
                      }
                      groups={staffCategoryGroups}
                      label={PRESCHOOL_STAFF}
                      checkboxValues={staffCategoryCheckboxValues}
                    />
                    <CheckboxGenerator
                      toggleStaffCategorySelection={
                        toggleStaffCategorySelection
                      }
                      groups={staffCategoryGroups}
                      label={OFFICE_STAFF}
                      checkboxValues={staffCategoryCheckboxValues}
                    />
                    <CheckboxGenerator
                      toggleStaffCategorySelection={
                        toggleStaffCategorySelection
                      }
                      groups={staffCategoryGroups}
                      label={OPERATIONS}
                      checkboxValues={staffCategoryCheckboxValues}
                    />
                  </div>
                  <div>
                    <CheckboxGenerator
                      toggleStaffCategorySelection={
                        toggleStaffCategorySelection
                      }
                      groups={staffCategoryGroups}
                      label={REBBEIM}
                      checkboxValues={staffCategoryCheckboxValues}
                    />
                    <CheckboxGenerator
                      toggleStaffCategorySelection={
                        toggleStaffCategorySelection
                      }
                      groups={staffCategoryGroups}
                      label={ADMINISTRATIVE_STAFF}
                      checkboxValues={staffCategoryCheckboxValues}
                    />
                  </div>
                  <div>
                    <CheckboxGenerator
                      toggleStaffCategorySelection={
                        toggleStaffCategorySelection
                      }
                      groups={staffCategoryGroups}
                      label={TEACHERS}
                      checkboxValues={staffCategoryCheckboxValues}
                    />
                  </div>
                  <div className="close-filters-mobile">
                    <span
                      className="link-clear-filter"
                      onClick={() => {
                        setShowStaffPanel(false);
                      }}
                    >
                      <Icon name="close" />
                      Close Filters
                    </span>
                  </div>
                </div>
              </SpringIn>
            )}

            {!showStaffPanel && Boolean(staffCategories.length) && (
              <div className="filter-labels">
                <LabelGenerator
                  staffCategories={staffCategories}
                  toggleStaffCategorySelection={toggleStaffCategorySelection}
                  checkboxValues={staffCategoryCheckboxValues}
                  clearCheckboxSelections={clearStaffCategorySelection}
                />
              </div>
            )}
          </div>

          {isLoading && <h3>Loading...</h3>}
          {isLoading || staffCategories.length === 0 || showStaffPanel || (
            <>
              {gavePreviouslyFilter && (
                <button
                  className="add-past-recipients-btn"
                  onClick={handleAddAllPrevRecipients}
                >
                  Add All Past Recipients to Cart
                </button>
              )}

              <PeopleTable
                people={displayedPeople}
                searchTerm={debouncedSearchTerm}
                selections={selections}
                setSelections={setSelections}
                staffCategories={staffCategories}
              />

              <div className="pagination-buttons">
                <Button
                  disabled={currentPage === 1}
                  className="btn-previous"
                  compact
                  primary
                  active={false}
                  onKeyPress={(e) => {
                    e.preventDefault();
                  }}
                  onClick={(e) => {
                    setCurrentPage(currentPage - 1);
                  }}
                >
                  <Icon name="angle left" />
                  <span>Previous</span>
                </Button>
                <div className="page-x-of-y">
                  Page {currentPage} of {lastPage} {'  '}{' '}
                </div>
                <Button
                  disabled={currentPage === lastPage}
                  className="btn-next"
                  compact
                  primary
                  onKeyPress={(e) => {
                    e.preventDefault();
                  }}
                  onClick={(e) => {
                    setCurrentPage(currentPage + 1);
                  }}
                >
                  <span>Next</span>
                  <Icon name="angle right" />
                </Button>
              </div>
            </>
          )}
        </div>
        <div></div>
      </div>
      <div className="next-button-div">
        <button className="next-btn-search" onClick={goToCartPage}>
          Go To Cart
        </button>
      </div>
    </div>
  );
};

export default SearchScreen;
