import { Redirect, Route, useLocation } from 'react-router';
import { LOGIN_ROUTE, ACCOUNT_INFO_ROUTE } from '../routes';
import React, { useContext, useEffect } from 'react';
import { useJwtToken } from '../hooks/useJwtToken';
import { AuthenticationContext } from '../contexts/AuthenticationProvider';

function PrivateRoute({ children, ...rest }) {
  let location = useLocation();
  const { isJwtValid } = useJwtToken();
  const { authenticated, phoneNumber } = useContext(AuthenticationContext);

  useEffect(() => {
    isJwtValid();
  }, []);

  if (!authenticated) {
    return (
      <Redirect
        to={{
          pathname: LOGIN_ROUTE,
          state: { from: location },
        }}
      />
    );
  } else if (authenticated && (phoneNumber == null || phoneNumber.length == 0)) {
    return (
      <Redirect
        to={{
          pathname: ACCOUNT_INFO_ROUTE,
          state: { from: location },
        }}
      />
    );
  }

  return <Route {...rest} render={({ location }) => children} />;
}

export default PrivateRoute;
