const sampleCustomer = {
  customerId: 4358,
  orgId: 2,
  createdAt: 1597276724240,
  updatedAt: 1600114775708,
  titleId: 1,
  title: 'Mr. & Mrs.',
  firstNames: 'Kalman & Chana',
  lastName: 'Hazins',
  addressNumber: '5919',
  streetId: 71,
  street: 'Simmonds Ave',
  cityId: 2,
  city: 'Baltimore',
  neighborhoodId: 1,
  neighborhood: 'Cheswolde',
  stateId: 22,
  state: 'Maryland',
  zip: '21215-3822',
  phone: '4103589473',
  email: 'kalmanh@gmail.com',
  newPassword: 'abc123',
  internal: false,
  defaultGreeting: 'Mr. & Mrs. Kalman & Chana Gitty Hazins',
  deactivatedOn: 0,
  disabled: null,
  cardOnly: false,
  paperOrder: false,
  reciprocation: false,
  basketWithoutOrders: true,
  staff: true,
  admin: false,
  staffName: null,
  staffPosition: null,
  stripeCustomerId: null,
  addressLine1: '5919 Simmonds Ave',
  addressLine2: null,
  addressLine3: 'Baltimore, MD 21215-3822',
  fullAddress: '5919 Simmonds Ave, Baltimore, MD 21215',
  googlePlaceId: 'ChIJv6Am8IsayIkR_GcSiZO1r3o',
  customId: 648,
  latitude: 39.36133955,
  longitude: -76.6824075784343,
  uuid: '7ef45e0a-2f9d-45b6-989f-64614c4a7cc1',
  staffCategory: null,
  staffCategoryId: 0,
};

export default sampleCustomer;
