import React, { useContext, useEffect } from 'react';
import './App.css';
import 'semantic-ui-css/semantic.min.css';
import SearchScreen from './components/SearchScreen';
import { Redirect } from 'react-router';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  useHistory,
  useLocation,
} from 'react-router-dom';

import LandingPage from './pages/LandingPage/LandingPage';
import RegistrationPage from './pages/RegistrationPage/RegistrationPage';
import 'fontsource-lato';
import AuthenticationProvider, {
  AuthenticationContext,
} from './contexts/AuthenticationProvider';
import { uuidLogin } from './services/loginService';
import ReviewCartPage from './pages/ReviewCartPage/ReviewCartPage';
import CheckoutPage from './pages/CheckoutPage/CheckoutPage';
import { CONTINGENCY_ON, PEKELACH_ORDERING_DONE } from './config';
import PekelachPage from './pages/PekelachPage/PekelachPage';
import EZHeader from './components/EZHeader/EZHeader';
import CartProvider from './contexts/CartProvider';
import EZFooter from './components/EZFooter/EZFooter';
import {
  ACCOUNT_INFO_ROUTE,
  CHECKOUT_ROUTE,
  CONFIRMATION_ROUTE,
  LANDING_ROUTE,
  LOGIN_ROUTE,
  PEKELACH_ROUTE,
  REGISTER_ROUTE,
  REVIEW_CART_ROUTE,
  SHALOCH_MANOS_ROUTE,
} from './routes';
import NoMatch from './components/NoMatch/NoMatch';
import LoginPage from './pages/LoginPage/LoginPage';
import ContingencyPage from './pages/ContingencyPage/ContingencyPage';
import HelpModal from './components/HelpModal/HelpModal';
import ConfirmationPage from './pages/ConfirmationPage/ConfirmationPage';
import AccountInfoPage from './pages/AccountInfoPage/AccountInfoPage';
import HttpsRedirect from 'react-https-redirect';
import PekelachOrderingDonePage from './pages/PekelachOrderingDonePage/PekelachOrderingDonePage';
import PrivateRoute from './PrivateRoute/PrivateRoute';
import { useJwtToken } from './hooks/useJwtToken';
import OrganizationProvider from './contexts/OrganizationProvider';
import { getCart } from './services/cartService';
import { CartContext } from './contexts/CartProvider';

export default function App() {
  return (
    <HttpsRedirect>
      <Router>
        <OrganizationProvider>
          <AuthenticationProvider>
            <CartProvider>
              <Main />
            </CartProvider>
          </AuthenticationProvider>
        </OrganizationProvider>
      </Router>
    </HttpsRedirect>
  );
}

const SilentLogin = ({ uuid }) => {
  const { setGreetingName, setLastName, setPhonenumber } = useContext(AuthenticationContext);
  const { setCart, setCartInitialized } = useContext(CartContext);
  let history = useHistory();
  const { isJwtValid } = useJwtToken();

  useEffect(() => {
    const login = async () => {
      const loginStatus = await uuidLogin({
        uuid,
        setGreetingName,
        setLastName,
        setPhonenumber
      });
      if (loginStatus) {
        const cart = await getCart();
        setCart(cart);
        setCartInitialized(true);
        history.push(SHALOCH_MANOS_ROUTE);
        return;
      }

      history.push(LOGIN_ROUTE);
    };
    login();
  });

  if (isJwtValid()) {
    return <Redirect to={SHALOCH_MANOS_ROUTE} />;
  }

  return <div>Loading...</div>;
};

const Main = () => {
  let location = useLocation();

  return (
    <>
      <HelpModal />
      <EZHeader />
      <div className="main-app-content">
        <Switch location={location}>
          <Route
            path={LOGIN_ROUTE}
            render={(props) => {
              const { search } = props.location;
              const queryParams = new URLSearchParams(search);
              const uuid = queryParams.get('uuid');
              if (uuid) {
                return <SilentLogin uuid={uuid} />;
              }

              return <LoginPage />;
            }}
          />
          <Route path={LOGIN_ROUTE}>
            <LoginPage />
          </Route>
          <Route exact path={LANDING_ROUTE}>
            {CONTINGENCY_ON ? <ContingencyPage /> : <LandingPage />}
          </Route>
          <Route path={REGISTER_ROUTE}>
            <RegistrationPage />
          </Route>
          <PrivateRoute path={SHALOCH_MANOS_ROUTE}>
            <SearchScreen />
          </PrivateRoute>
          <PrivateRoute path={SHALOCH_MANOS_ROUTE}>
            {PEKELACH_ORDERING_DONE ? (
              <PekelachOrderingDonePage />
            ) : (
              <PekelachPage />
            )}
          </PrivateRoute>
          <PrivateRoute path={REVIEW_CART_ROUTE}>
            <ReviewCartPage />
          </PrivateRoute>
          <PrivateRoute path={CHECKOUT_ROUTE}>
            <CheckoutPage />
          </PrivateRoute>
          <PrivateRoute path={CONFIRMATION_ROUTE}>
            <ConfirmationPage />
          </PrivateRoute>
          <Route path={ACCOUNT_INFO_ROUTE}>
            <AccountInfoPage />
          </Route>
          <Route path="*">
            <NoMatch />
          </Route>
        </Switch>
      </div>
      <EZFooter />
    </>
  );
};
