import React, { useContext } from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './CardSection.css';
import { CartContext } from '../../contexts/CartProvider';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      color: '#7A869A',
      fontFamily: 'Lato',
      fontSmoothing: 'antialiased',
      fontSize: '14px',
      letterSpacing: 0,
      lineHeight: '20px',
      '::placeholder': {
        color: 'black',
        fontFamily: 'Lato',
        fontWeight: 'bold',
        fontSmoothing: 'antialiased',
        fontSize: '14px',
        letterSpacing: 0,
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

const CardSection = () => {
  const { setCreditCardComplete } = useContext(CartContext);

  return (
    <label>
      <div className="card-information-title">Card information</div>
      <CardElement
        options={CARD_ELEMENT_OPTIONS}
        className="ez-card-element"
        onChange={({ complete }) => setCreditCardComplete(complete)}
      />
    </label>
  );
};

export default CardSection;
