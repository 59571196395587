import React from 'react';
import { useSpring, animated as a } from 'react-spring';

const SpringIn = ({ children }) => {
  const animatedProps = useSpring({
    opacity: 1,
    marginTop: 0,
    from: { marginTop: -100, opacity: 0 },
    config: { mass: 1, tension: 50, friction: 10 },
  });
  return <a.div style={{ ...animatedProps }}>{children}</a.div>;
};

export default SpringIn;
