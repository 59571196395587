import React, { useState } from 'react';
import './RegistrationPage.css';

import EmailInfoBox from '../../components/EmailInfoBox/EmailInfoBox';
import VerifyPinBox from '../../components/VerifyPinBox/VerifyPinBox';
import ReviewInfoBox from '../../components/ReviewInfoBox/ReviewInfoBox';
import SearchCustomerAndRegister from '../../components/SearchCustomerAndRegister/SearchCustomerAndRegister';

export const REGISTRATION_STEPS = {
  CONFIRM_EMAIL: 'CONFIRM_EMAIL',
  VERIFY_PIN: 'VERIFY_PIN',
  REVIEW_CUSTOMER_INFO: 'REVIEW_CUSTOMER_INFO',
  SEARCH_CUSTOMER_AND_REGISTER: 'SEARCH_CUSTOMER_AND_REGISTER',
  NEW_CUSTOMER_INFO: 'NEW_CUSTOMER_INFO',
};

const RegistrationPage = (props) => {
  const [registrationStep, setRegistrationStep] = useState(
    REGISTRATION_STEPS.CONFIRM_EMAIL
  );
  const [accountInfo, setAccountInfo] = useState({});
  const [appEmail, setAppEmail] = useState('');
  const [appPassword, setAppPassword] = useState('');
  const [appPin, setAppPin] = useState('');

  return (
    <div className="registration-page">
      {registrationStep === REGISTRATION_STEPS.CONFIRM_EMAIL && (
        <EmailInfoBox
          setRegistrationStep={setRegistrationStep}
          appEmail={appEmail}
          setAppEmail={setAppEmail}
        />
      )}
      {registrationStep === REGISTRATION_STEPS.SEARCH_CUSTOMER_AND_REGISTER && (
        <SearchCustomerAndRegister
          appEmail={appEmail}
          appPassword={appPassword}
          setRegistrationStep={setRegistrationStep}
          setAccountInfo={setAccountInfo}
          appPin={appPin}
        />
      )}
      {registrationStep === REGISTRATION_STEPS.VERIFY_PIN && (
        <VerifyPinBox
          setRegistrationStep={setRegistrationStep}
          setAccountInfo={setAccountInfo}
          setAppPin={setAppPin}
          setAppPassword={setAppPassword}
          email={appEmail}
        />
      )}
      {registrationStep === REGISTRATION_STEPS.REVIEW_CUSTOMER_INFO && (
        <ReviewInfoBox
          accountInfo={accountInfo}
          setRegistrationStep={setRegistrationStep}
          email={appEmail}
        />
      )}
      {registrationStep === REGISTRATION_STEPS.NEW_CUSTOMER_INFO && (
        <ReviewInfoBox
          accountInfo={accountInfo}
          setRegistrationStep={setRegistrationStep}
          email={appEmail}
          newPassword={appPassword}
          pin={appPin}
          newCustomer
        />
      )}
    </div>
  );
};

export default RegistrationPage;
