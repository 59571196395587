import React from 'react';
import { Icon, Label } from 'semantic-ui-react';
import './LabelGenerator.css';

const LabelGenerator = ({
  staffCategories,
  toggleStaffCategorySelection = () => {},
  checkboxValues,
  clearCheckboxSelections,
}) => {
  const labelIds = Object.entries(checkboxValues).reduce(
    (accum, curr) => (curr[1] ? accum.concat(curr[0]) : accum),
    []
  );

  const labelName = (id) =>
    staffCategories.find(
      ({ staffCategoryId }) => staffCategoryId.toString() === id
    ).category;

  if (!labelIds.length) {
    return null;
  }

  return (
    <div className="label-generator">
      {labelIds.map((labelId) => (
        <Label
          key={labelId}
          basic
          color="blue"
          className="filter-label"
          onClick={() => toggleStaffCategorySelection(labelId)}
        >
          {labelName(labelId)}
          <Icon name="delete" />
        </Label>
      ))}
      <span
        className="link-clear-filter"
        onClick={() => {
          clearCheckboxSelections();
        }}
      >
        Clear Filters
      </span>
    </div>
  );
};

export default LabelGenerator;
