import React, { useState, useRef, useEffect } from 'react';
import './EmailInfoBox.css';
import { REGISTRATION_STEPS } from '../../pages/RegistrationPage/RegistrationPage';
import { BACKEND_URL } from '../../config';

const emailIsValid = (email = '') => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
};

const EmailInfoBox = ({ setRegistrationStep, appEmail, setAppEmail }) => {
  const [invalidEmail, setInvalidEmail] = useState(appEmail.trim() === '');
  const [email, setEmail] = useState(appEmail);

  const requestPin = async (e) => {
    e.preventDefault();
    setAppEmail(email);
    setRegistrationStep(REGISTRATION_STEPS.VERIFY_PIN);

    const response = await fetch(`${BACKEND_URL}api/v1/auth/sendpin`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: `email=${email}`,
    }).catch((err) => {
      console.error('Error:', err);
    });

    console.log('RESPONSE: ', response);
  };

  const emailRef = useRef(null);
  useEffect(() => {
    emailRef.current.focus();
  }, []);

  const validateEmail = (e) => {
    const emailValue = e.target.value;
    setInvalidEmail(!emailIsValid(emailValue));
    setEmail(emailValue);
  };

  return (
    <div className="email-info-box">
      <div className="confirm-email-content-box">
        <div className="confirm-email-content-title">
          Register to Order Online
        </div>
        <div className="confirm-email-content-subtitle">
          <strong>First, please enter your email address.</strong> We’ll send
          you a code to confirm your address.
        </div>

        <form onSubmit={requestPin}>
          <label htmlFor="email" className="email">
            Email Address
          </label>
          <input
            name="email"
            value={email}
            type="text"
            ref={emailRef}
            className="email"
            onChange={validateEmail}
          ></input>
          <div className="email-help-text">
            If you have placed an order in the past, please use the email
            address linked to your account, so we can find your information.
          </div>
          <button type="submit" disabled={invalidEmail} className="email">
            Email Me The Code
          </button>
        </form>
      </div>
    </div>
  );
};

export default EmailInfoBox;
