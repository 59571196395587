import React, { useContext } from 'react';
import './EZFooter.css';
import ptaLogo from '../../images/PTAlogo.svg';
import { OrganizationContext } from '../../contexts/OrganizationProvider';

const EZFooter = (props) => {
  const { phone } = useContext(OrganizationContext);

  return (
    <div className="ezfooter">
      <div className="call-for-help">
        <div>Need help?</div>
        <div>Call us at {phone}</div>
      </div>
      <div className="logo-with-text">
        <img src={ptaLogo} alt="PTA Logo" />
        <div className="footer-logo-text">
          A project of the YKY/TI <br />
          Parent-Teacher Association
        </div>
      </div>
    </div>
  );
};

export default EZFooter;
