import React, { useContext, useState } from 'react';
import { Button } from 'semantic-ui-react';
import { CartContext } from '../../contexts/CartProvider';
import EditGreetingModal from '../EditGreetingModal/EditGreetingModal';
import './CartDefaultGreeting.css';

const CartDefaultGreeting = () => {
  const { defaultGreeting, greetingSuffix, greetingSuffixes } =
    useContext(CartContext);

  const [editMode, setEditMode] = useState(false);
  const toggleEditMode = () => setEditMode((editMode) => !editMode);

  return (
    <div className="cart-default-greeting">
      <div className="cart-default-greeting-title">Greeting</div>
      <div>
        <div className="cart-default-greeting-text">
          This greeting will be used for all your recipients.
        </div>

        <span id="greetingText">
          {defaultGreeting}
          &nbsp;
          {greetingSuffixes[greetingSuffix]}
        </span>

        <div id="editGreetingBtn">
          <Button
            compact
            primary
            onKeyPress={(e) => {
              e.preventDefault();
            }}
            onClick={toggleEditMode}
          >
            <span>Edit Greeting</span>
          </Button>
        </div>
      </div>

      <EditGreetingModal open={editMode} toggleOpen={toggleEditMode} />
    </div>
  );
};

export default CartDefaultGreeting;
