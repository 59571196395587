import React from 'react';
import { Modal } from 'semantic-ui-react';
import './EzErrorModal.css';

const EZErrorModal = ({
  size = 'mini',
  open,
  headerText,
  children,
  onClose,
}) => {
  return (
    <Modal
      size={size}
      dimmer={'blurring'}
      closeIcon
      open={open}
      onClose={onClose}
    >
      <Modal.Header>{headerText}</Modal.Header>
      <Modal.Content>
        <div style={{ fontSize: '14px' }}>{children}</div>
      </Modal.Content>
    </Modal>
  );
};

export default EZErrorModal;
