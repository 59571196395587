import React, { useContext } from 'react';
import { Table } from 'semantic-ui-react';
import { CartContext, SHALOCH_CUSTOMER_IDS } from '../../contexts/CartProvider';
import './CartLineItem.css';

const CartLineItem = ({
  customerId: toCustomerId,
  firstNames,
  lastName,
  title,
  addressLine1,
  showAddress,
}) => {
  const fullName = `${lastName}, ${title} ${firstNames}`;

  const { cart, updateCart } = useContext(CartContext);

  const handleRemove = (recordId) => {
    const selectedIds = cart[SHALOCH_CUSTOMER_IDS];
    updateCart(
      SHALOCH_CUSTOMER_IDS,
      selectedIds.filter((id) => id !== recordId)
    );
  };

  return (
    <Table.Row>
      <Table.Cell>
        <div className="line-item-name">{fullName}</div>
        {showAddress && <div>{addressLine1}</div>}
      </Table.Cell>
      <Table.Cell>
        <div className="toggle-and-remove-cell">
          <button
            className="link-action remove-link"
            onClick={() => handleRemove(toCustomerId)}
          >
            Remove
          </button>
        </div>
      </Table.Cell>
    </Table.Row>
  );
};

export default CartLineItem;
