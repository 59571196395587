import React from 'react';
import { Link } from 'react-router-dom';
import { PEKELACH_ORDERING_DONE } from '../../config';
import {
  PEKELACH_ROUTE,
  REVIEW_CART_ROUTE,
  SHALOCH_MANOS_ROUTE,
} from '../../routes';
import './EZBreadCrumb.css';

const EZBreadCrumb = (props) => {
  if (PEKELACH_ORDERING_DONE) {
    return null;
  }

  return (
    <ul className="breadcrumb">
      <li>
        <Link to={SHALOCH_MANOS_ROUTE}>&emsp;&emsp;</Link>
        {/* <Link to={PEKELACH_ROUTE}>Pekelach</Link> */}
      </li>
      <li>
        <Link to={SHALOCH_MANOS_ROUTE}>Mishloach Manos</Link>
      </li>
      <li>
        <Link to={REVIEW_CART_ROUTE}>Cart</Link>
      </li>
    </ul>
  );
};

export default EZBreadCrumb;
