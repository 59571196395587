import React from 'react';
import './FloatyErrorMsg.css';
import error_img_flipped from '../../images/status_error_flipped.svg';

const FloatyErrorMsg = (props) => (
  <div className="floaty-error-message">
    <img
      src={error_img_flipped}
      className="error-img"
      alt="error-message-icon"
    />
    <div className="error-content-box">
      <div className="error-msg-title">
        <strong>This e-mail is already registered.</strong>
      </div>
      <p className="error-msg-content">
        The email you entered is already registered. Please proceed to Sign In.
      </p>
    </div>
  </div>
);

export default FloatyErrorMsg;
