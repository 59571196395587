import { BACKEND_URL, JWT_KEY } from '../config';
import { getWithExpiry } from './localStorageService';

export const getCart = async () => {
  const response = await fetch(`${BACKEND_URL}api/v1/customer/cart`, {
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
  }).catch((err) => {
    console.error('Error:', err);
  });

  if (!response.ok) {
    console.log('ERROR Response: ', response);
    throw new Error('Unable to retrieve cart!');
  }

  return await response.json();
};

export const updateCustomerCart = async (cart) => {
  const response = await fetch(`${BACKEND_URL}api/v1/customer`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
    body: JSON.stringify({
      cart,
    }),
  }).catch((err) => {
    console.error(`Error updateCart:`, err);
  });

  if (!response.ok) {
    console.log('ERROR in response (updateCart): ', response);
    return;
  }
};
