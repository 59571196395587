import React, { useContext, useEffect } from 'react';
import '../../css/bootstrap-limit-scope.scss';
import './LandingPage.css';
import t2 from '../../images/landing/t2.svg';
import team from '../../images/landing/team.png';
import g1 from '../../images/landing/g1.png';
import g2 from '../../images/landing/g2.png';
import g3 from '../../images/landing/g3.png';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { LOGIN_ROUTE, SHALOCH_MANOS_ROUTE, REGISTER_ROUTE } from '../../routes';
import { CartContext } from '../../contexts/CartProvider';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { gotoBtnText } from './LandingPageUtil';
import { OrganizationContext } from '../../contexts/OrganizationProvider';

const LandingPage = (props) => {
  const { itemsInCart } = useContext(CartContext);
  const { setShowCart, setShowSubheader, authenticated } = useContext(
    AuthenticationContext
  );

  const { orderingActive = true, phone, activeYearPrice } = useContext(OrganizationContext);

  useEffect(() => {
    setShowCart(true);
    setShowSubheader(false);
  }, []);

  return (
    <div className="landing-page bootstrap-inside">
      <section style={{ background: '#f4f5f7' }}>
        <div className="cover-bg-0">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center bg-text-1">
                <h1>
                  {orderingActive
                    ? 'Your one stop shop'
                    : 'ordering has ended for this year'}
                </h1>

                {!orderingActive ? (
                  <div className="section-title">
                    <div className="ordering-done">
                      לשנה הבאה בירושלים הבנויה
                    </div>
                  </div>
                ) : (
                  <div className="section-title">
                    <h2> For All Mishloach Manos Needs</h2>
                  </div>
                )}

                {!orderingActive && (
                  <div className="have-questions">
                    Have questions? Contact us at{' '}
                    <a href="mailto:ezpurim@torahinstitute.org">
                      ezpurim@torahinstitute.org
                    </a>{' '}
                    or <a href={'tel: ' + phone}> {phone} </a>
                  </div>
                )}

                {!orderingActive || (
                  <Link
                    to={authenticated ? SHALOCH_MANOS_ROUTE : REGISTER_ROUTE}
                    className="btn btn3"
                  >
                    {gotoBtnText(authenticated, itemsInCart)}
                  </Link>
                )}

                {!orderingActive || authenticated || (
                  <p>
                    I’m already registered,{' '}
                    <Link to={LOGIN_ROUTE}>login instead</Link>.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{ background: '#E9E5D8' }}
        className="pt-5 pb-5  border-top-1"
      >
        <div className="container pt-md-4 pb-md-4">
          <div className="row">
            <div className="col-md-6">
              <img src={t2} alt="img" className="img-fluid" width="199" />
              <p className="text mt-4">
                Your donation provides direct support to enhance our talmidim’s
                educational experience. While your friends enjoy the classy
                mishloach manos, your help is providing support to a struggling
                student. With our myriad remedial programs, such as subsidized
                tutoring, a brand new middle school GOLD program, and
                Dyslexia/L'Aylah class, our focus is giving each student the
                opportunity to shine.
              </p>
            </div>
            <div className="col-md-6">
              <img src={team} alt="img" className="img-fluid mt-4" />
            </div>
          </div>
        </div>
      </section>

      <section style={{ background: '#031633' }} className="border-both">
        <div className="cover-bg-1">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center bg-text-1">
                <div className="section-title-2">
                  <h2 className="text-uppercase">why ezpurim?</h2>
                </div>
              </div>
            </div>

            <div className="row mt-md-4">
              <div className="col-md-6 col-lg-3 d-flex">
                <div className="box1 w-100">
                  <h2>Easy</h2>
                  <p>
                    Recipients with ten or more names receive a mishloach manos
                    package. Others receive a beautiful Purim card.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex">
                <div className="box1 w-100">
                  <h2>convenient</h2>
                  <p>
                    Take advantage of our reciprocity program and make sure to
                    send back to all those who send you.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex">
                <div className="box1 w-100">
                  <h2>beautiful</h2>
                  <p>
                    Our curated packages will enhance the Simchas Purim of all
                    your recipients.
                  </p>
                </div>
              </div>

              <div className="col-md-6 col-lg-3 d-flex">
                <div className="box1 w-100">
                  <h2>time saving</h2>
                  <p>
                    Take advantage of our beautiful pre-packaged Pekelach so
                    that you can spend precious time with your family.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ background: '#E9E5D8' }} className="sect-3">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center bg-text-1">
              <div className="section-title-3">
                <h2 className="text-uppercase">how to order</h2>
              </div>
            </div>
          </div>

          <div className="row mt-md-4">
            <div className="col-md-6 col-lg-3 d-flex">
              <div className="box2 w-100">
                <span>
                  <Icon name="user plus" />
                </span>
                <h4>step 1</h4>
                <h2>Register</h2>
                <p>
                  Enter your email address and confirm your account information.
                </p>
              </div>
            </div>
            {/* <div className="col-md-6 col-lg-3 d-flex">
              <div className="box2 w-100">
                <span>
                  <Icon name="gift" />
                </span>
                <h4>step 2</h4>
                <h2>order pekelach</h2>
                <p>
                  Select from multiple pekelach options and add as many as you
                  need.
                </p>
              </div>
            </div> */}
            <div className="col-md-6 col-lg-3 d-flex">
              <div className="box2 w-100">
                <span>
                  <Icon name="clipboard list" />
                </span>
                <h4>step 2</h4>
                <h2>Add Names</h2>
                <p>
                  Search for your recipients and add their names to the list. ${activeYearPrice} per name.
                </p>
              </div>
            </div>
            <div className="col-md-6 col-lg-3 d-flex">
              <div className="box2 w-100">
                <span>
                  <Icon name="shopping cart" />
                </span>
                <h4>step 3</h4>
                <h2>Checkout</h2>
                <p>Pay for your order and we take care of the rest.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section style={{ background: '#f4f5f7' }}>
        <div className="cover-bg-1 cover-bg-2">
          <div className="container">
            <div className="row">
              <div className="col-md-12 text-center bg-text-1">
                <h5>Packages from Previous Years</h5>
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-4 mb-md-4">
                <img
                  src={g1}
                  className="img-fluid mx-auto d-block"
                  width="250px"
                  alt="img"
                />
              </div>
              <div className="col-md-4 mb-md-4">
                <img
                  src={g2}
                  className="img-fluid mx-auto d-block"
                  width="250px"
                  alt="img"
                />
              </div>
              <div className="col-md-4 mb-4">
                <img
                  src={g3}
                  className="img-fluid mx-auto d-block"
                  width="250px"
                  alt="img"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LandingPage;
