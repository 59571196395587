import React, { useContext, useEffect, useState } from 'react';
import './ReviewCartPage.css';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import PekelahCart from '../../components/PekelahCart/PekelahCart';
import ShalochManosCart from '../../components/ShalochManosCart/ShalochManosCart';
import { CartContext, SHALOCH_CUSTOMER_IDS } from '../../contexts/CartProvider';
import OrderSummary from '../../components/OrderSummary/OrderSummary';
import QuickOrderSummary from '../../components/QuickOrderSummary/QuickOrderSummary';
import { PEKELACH_ORDERING_DONE } from '../../config';

const ReviewCartPage = (props) => {
  const { setShowSubheader, setShowCart } = useContext(AuthenticationContext);
  const { cart, people } = useContext(CartContext);

  const [selectedPeople, setSelectedPeople] = useState([]);

  useEffect(() => {
    setShowCart(false);
    setShowSubheader(false);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const selectedIds = cart[SHALOCH_CUSTOMER_IDS] || [];
    setSelectedPeople(
      people.filter(({ customerId }) => selectedIds.includes(customerId))
    );
  }, [cart, people]);

  return (
    <div className="review-cart-page">
      <div className="main-content">
        <QuickOrderSummary />
        {/* {PEKELACH_ORDERING_DONE || <PekelahCart />} */}
        <ShalochManosCart people={selectedPeople} />
      </div>

      <OrderSummary actionLabel="Checkout" />
    </div>
  );
};

export default ReviewCartPage;
