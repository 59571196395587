import React, { useState, useRef, useEffect, useContext } from 'react';
import './VerifyPinBox.css';
import { REGISTRATION_STEPS } from '../../pages/RegistrationPage/RegistrationPage';
import classnames from 'classnames';
import error_img from '../../images/status_error.svg';
import EZInput from '../EZInput/EZInput';
import FloatyErrorMsg from '../FloatyErrorMsg/FloatyErrorMsg';
import { registerAccountService } from '../../services/registration';
import PacmanLoader from 'react-spinners/PacmanLoader';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { invisibleLogin } from '../../services/loginService';
import { BACKEND_URL } from '../../config';

const VerifyPinBox = ({
  setRegistrationStep,
  setAccountInfo,
  email,
  setAppPin,
  setAppPassword,
}) => {
  const [pin, setPin] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [verifying, setVerifying] = useState(false);
  const pinRef = useRef(null);
  const { setAuthenticated, setGreetingName, setLastName, setPhonenumber } = useContext(
    AuthenticationContext
  );

  useEffect(() => {
    pinRef.current.focus();
  }, []);

  const handleChangePin = (e) => {
    const pin = e.target.value;
    setPin(pin);
    setAppPin(pin);
  };
  const handleChangePassword = (e) => {
    const newPassword = e.target.value;
    setNewPassword(newPassword);
    setAppPassword(newPassword);
  };
  const handleChangeConfirmedPassword = (e) =>
    setConfirmedPassword(e.target.value);

  const registerAccount = async (e) => {
    e.preventDefault();

    setErrors({});
    setVerifying(true);

    if (newPassword !== confirmedPassword) {
      setTimeout(() => {
        setVerifying(false);
        setErrors({
          confirmedPassword: "Passwords don't match. Please try again",
        });
      }, 1300);

      return;
    }

    const { errors, accountInfo } = await registerAccountService({
      email,
      newPassword,
      pin,
    });

    setTimeout(async () => {
      if (errors) {
        setVerifying(false);

        if (errors.userNotFound) {
          setRegistrationStep(REGISTRATION_STEPS.SEARCH_CUSTOMER_AND_REGISTER);
          return;
        }
        setErrors({ ...errors });
        return;
      }

      // No errors - login
      const loginStatus = await invisibleLogin({
        email,
        password: newPassword,
        setAuthenticated,
        setGreetingName,
        setLastName,
        setPhonenumber
      });
      setAccountInfo(accountInfo);
      if (loginStatus) {
        setRegistrationStep(REGISTRATION_STEPS.REVIEW_CUSTOMER_INFO);
      }
      setVerifying(false);
    }, 2000);
  };

  const allFieldsNonEmpty =
    pin.trim() && newPassword.trim() && confirmedPassword.trim();

  const resendPin = async (e) => {
    e.preventDefault();

    const resendPinResponse = await fetch(`${BACKEND_URL}api/v1/auth/sendpin`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-type': 'application/x-www-form-urlencoded',
      },
      body: `email=${email}`,
    }).catch((err) => {
      console.error('Error:', err);
    });

    console.log({ resendPinResponse });
  };

  return (
    <>
      {errors.userExists && <FloatyErrorMsg />}

      <div className="verify-pin-box">
        <div className="verify-pin-content-box">
          <div className="verify-pin-content-title">
            Register to Order Online
          </div>
          <div className="verify-pin-content-subtitle">
            We’ve sent a 6-character code to <strong>{email}</strong>. The code
            expires shortly, so please enter it soon.{' '}
            <button
              onClick={() =>
                setRegistrationStep(REGISTRATION_STEPS.CONFIRM_EMAIL)
              }
              className="link-action"
            >
              Change my email address.
            </button>
          </div>

          <form onSubmit={registerAccount}>
            <div className="confirmation-code-container">
              <label className="code" htmlFor="confirmationCode">
                Confirmation Code
              </label>
              <input
                name="confirmationCode"
                value={pin}
                type="text"
                ref={pinRef}
                className={classnames('ezinput', {
                  error: errors.pin,
                })}
                onChange={handleChangePin}
              ></input>
              {errors.pin && (
                <div className="error-message-div">
                  <img src={error_img} alt="error-message-icon" />
                  <span className="error-message">{errors.pin}</span>
                </div>
              )}

              <div className="link-action" onClick={resendPin}>
                Resend the code
              </div>
            </div>
            <div className="password-container">
              <EZInput
                className="password-field"
                label="New Password"
                labelClassName="code"
                name="newPassword"
                type="password"
                value={newPassword}
                handleValueChange={handleChangePassword}
                inputProps={{ autoComplete: 'new-password' }}
              />

              <EZInput
                errors={errors}
                className="password-field"
                label="Confirm New Password"
                labelClassName="code"
                name="confirmedPassword"
                type="password"
                value={confirmedPassword}
                handleValueChange={handleChangeConfirmedPassword}
                inputProps={{ autoComplete: 'new-password' }}
              />
            </div>
            <button
              type="submit"
              disabled={!allFieldsNonEmpty}
              className="register-account"
            >
              {verifying ? (
                <div style={{ marginTop: '-10px', marginLeft: '20px' }}>
                  <PacmanLoader size={10} loading={true} color={'white'} />
                </div>
              ) : (
                <span>Access My Account</span>
              )}
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default VerifyPinBox;
