import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { BACKEND_URL, JWT_KEY } from '../../config';
import { CartContext, SHALOCH_CUSTOMER_IDS } from '../../contexts/CartProvider';
import { getWithExpiry } from '../../services/localStorageService';
import { currencyDisplay } from '../../services/util';
import './QuickOrderSummary.css';
import PacmanLoader from 'react-spinners/PacmanLoader';

import { CHECKOUT_ROUTE } from '../../routes';
import {
  addItemOrders,
  calculateManosTotal,
  calculatePekalachTotal,
  calculateTotal,
  plularize,
} from '../OrderSummary/OrderSummaryUtil';

const QuickOrderSummary = (props) => {
  const {
    numOfShalochManosItems,
    itemsInCart,
    pekalachs,
    numOfPekelachItems,
    cart,
    reciprocityFlag,
    setOrderInfo,
    defaultGreeting,
    greetingSuffix,
  } = useContext(CartContext);

  const history = useHistory();
  const [obtainingPaymentToken, setObtainingPaymentToken] = useState(false);

  const handleCheckout = () => {
    setObtainingPaymentToken(true);
    const result = {};
    result.defaultGreeting = defaultGreeting;
    result.greetingSuffix = greetingSuffix;
    result.reciprocity = reciprocityFlag;

    if (numOfPekelachItems > 0) {
      addItemOrders(result, cart, pekalachs);
    }

    result.orders = [];
    if (numOfShalochManosItems > 0) {
      result.orders = cart[SHALOCH_CUSTOMER_IDS].map((toCustomerId) => ({
        toCustomerId: parseInt(toCustomerId),
      }));
    }
    result.year = 2021;
    fetch(`${BACKEND_URL}api/v1/payment`, {
      method: 'POST',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
      },
      body: JSON.stringify(result),
    })
      .then((response) => response.json())
      .then((data) => {
        setOrderInfo(data);
        history.push(CHECKOUT_ROUTE);
      })
      .catch((error) => console.log('No way! ', error));
  };

  return (
    <div className="quick-order-summary">
      <div>
        <div className="quick-order-summary-title">Order Summary</div>

        <div className="quick-total">
          <span>Total:</span>
          <span className="quick-total-amt">
            {currencyDisplay(calculateTotal(cart, pekalachs))}
          </span>
        </div>

        {Boolean(numOfPekelachItems) && (
          <div className="quick-pekelach-total">
            Pekelach Total ({numOfPekelachItems}{' '}
            {plularize('item', numOfPekelachItems)})
            <span className="pekalach-total">
              {currencyDisplay(calculatePekalachTotal(cart, pekalachs))}
            </span>
          </div>
        )}

        {Boolean(numOfShalochManosItems) && (
          <div className="quick-manos-total">
            Mishloach Manos Total ({numOfShalochManosItems}{' '}
            {plularize('item', numOfShalochManosItems)})
            <span className="manos-total">
              {currencyDisplay(calculateManosTotal(cart))}
            </span>
          </div>
        )}

        <div className="quick-checkout-container">
          <button
            className="quick-checkout"
            disabled={itemsInCart === 0}
            onClick={handleCheckout}
          >
            {obtainingPaymentToken ? (
              <div
                style={{
                  width: '50px',
                  marginTop: '-10px',
                  marginLeft: 'auto',
                  marginRight: 'auto',
                }}
              >
                <PacmanLoader size={10} loading={true} color={'white'} />
              </div>
            ) : (
              <span>Proceed to Checkout</span>
            )}
          </button>
        </div>
      </div>
    </div>
  );
};

export default QuickOrderSummary;
