import { BACKEND_URL, JWT_KEY } from '../config';
import { getWithExpiry } from './localStorageService';

export const updateCustomer = async ({
  titleId,
  firstNames,
  lastName,
  fullAddress,
  addressLine2,
  phone,
  reciprocation,
}) => {
  const thisYear = new Date().getFullYear();
  const response = await fetch(`${BACKEND_URL}api/v1/customer`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
    body: JSON.stringify({
      titleId,
      firstNames,
      lastName,
      fullAddress,
      addressLine2,
      phone,
      reciprocation,
      year: thisYear,
    }),
  }).catch((err) => {
    console.error(`Error updateCustomer:`, err);
  });

  if (!response.ok) {
    console.log('ERROR in response (updateCustomer): ', response);
    return;
  }

  const result = await response.json();
  return { result };
};

export const createCustomer = async ({
  addressLine2,
  email,
  firstNames,
  fullAddress,
  lastName,
  newPassword,
  orgId,
  phone,
  pin,
  reciprocation,
  titleId,
}) => {
  const response = await fetch(`${BACKEND_URL}api/v1/auth/create`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
    },
    body: JSON.stringify({
      addressLine2,
      email,
      firstNames,
      fullAddress,
      lastName,
      newPassword,
      orgId,
      phone,
      pin,
      reciprocation,
      titleId,
    }),
  }).catch((err) => {
    console.error(`Error createCustomer:`, err);
  });

  if (!response.ok) {
    console.log('ERROR in response (createCustomer): ', await response.text());
    throw new Error(response);
  }

  const result = await response.text();
  return { result };
};

export const getCustomerInfo = async (customerId) => {
  const response = await fetch(`${BACKEND_URL}api/v1/customer/${customerId}`, {
    mode: 'cors',
    headers: {
      'Content-type': 'application/json',
      Authorization: `Bearer ${getWithExpiry(JWT_KEY)}`,
    },
  }).catch((err) => {
    console.error('Error:', err);
  });

  if (!response.ok) {
    console.log('ERROR Response: ', response);
    return;
  }

  const accountInfo = await response.json();
  console.log('RESPONSE REASON: ', accountInfo);
  return accountInfo;
};
