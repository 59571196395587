import React, { useContext, useState, useEffect } from 'react';
import './ReviewInfoBox.css';
import EZInput from '../EZInput/EZInput';
import Switch from 'react-switch';
import PlacesAutocomplete from '../PlacesAutocomplete';
import TitlesCombo from '../TitlesCombo/TitlesCombo';
import { createCustomer, updateCustomer } from '../../services/customerService';
import { useHistory } from 'react-router-dom';
import sampleCustomer from '../../fixtures/sampleCustomer';
import { invisibleLogin } from '../../services/loginService';
import { AuthenticationContext } from '../../contexts/AuthenticationProvider';
import { LOGIN_ROUTE } from '../../routes';
import EzErrorModal from '../EzErrorModal/EzErrorModal';
import { setWithExpiry } from '../../services/localStorageService';
import {  USER_PHONE_NUMBER } from '../../config';

const REQUIRED_FIELD_MSG = 'Required Field';

const ReviewInfoBox = ({
  accountInfo = sampleCustomer,
  newCustomer = false,
  email,
  newPassword,
  pin,
}) => {
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const {
    setAuthenticated,
    setGreetingName,
    setLastName: setLastNameInContext,
  } = useContext(AuthenticationContext);
  const [openValidationModal, setOpenValidationModal] = useState(false);

  const {
    titleId: acctTitleId,
    firstNames: acctFirstNames,
    lastName: acctLastName,
    fullAddress: acctAddress,
    addressLine2: acctAddressLine2,
    googlePlaceId: acctGooglePlaceId,
    phone: acctPhoneNumber,
    reciprocation: acctReciprocation,
  } = accountInfo;

  const [titleId, setTitleId] = useState(acctTitleId || 1);
  const [firstNames, setFirstNames] = useState(acctFirstNames || '');
  const [lastName, setLastName] = useState(acctLastName || '');
  const [addres, setAddress] = useState(acctAddress || '');
  const [addressLine2, setAddressLine2] = useState(acctAddressLine2 || '');
  const [googlePlaceId, setGooglePlaceId] = useState(acctGooglePlaceId || '');
  const [phoneNumber, setPhoneNumber] = useState(acctPhoneNumber || '');
  const { setPhonenumber } = useContext(AuthenticationContext);
  const [reciprocationFlag, setReciprocationFlag] = useState(
    Boolean(acctReciprocation)
  );
  const [addressLoading, setAddressLoading] = useState(true);

  useEffect(() => {
    if(!newCustomer) {
      setFirstNames(acctFirstNames);
      setTitleId(acctTitleId);
      setLastName(acctLastName);
      setAddress(acctAddress);
      setAddressLine2(acctAddressLine2);
      setPhoneNumber(acctPhoneNumber);
      setReciprocationFlag(Boolean(acctReciprocation));
    } else {
      setAddressLoading(false)
    }
  },[acctFirstNames, acctTitleId, acctLastName, acctAddress, acctAddressLine2, acctPhoneNumber, acctReciprocation]);

  useEffect(() => {
    if(addres) {
      setAddressLoading(false)
    }
  });

  const handleChangeFirstNames = (e) => {
    setFirstNames(e.target.value);

    const { f, ...otherErrors } = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({ ...otherErrors, f: REQUIRED_FIELD_MSG });
    } else {
      setErrors({ ...otherErrors });
    }
  };
  const handleChangeLastName = (e) => {
    setLastName(e.target.value);

    const { l, ...otherErrors } = errors;
    if (e.target.value.trim().length === 0) {
      setErrors({ ...otherErrors, l: REQUIRED_FIELD_MSG });
    } else {
      setErrors({ ...otherErrors });
    }
  };

  const handleChangePhoneNumber = (e) => setPhoneNumber(e.target.value);
  const handleChangeReciprocation = (checked) => setReciprocationFlag(checked);
  const handleChangeAddress = ({ address, googlePlaceId }) => {
    setAddress(address);
    setGooglePlaceId(googlePlaceId);

    const { addres, ...otherErrors } = errors;
    if (address.trim().length === 0) {
      setErrors({ ...otherErrors, addres: 'Required Field' });
    } else {
      setErrors({ ...otherErrors });
    }
  };
  const handleChangeAddressLine2 = (e) => setAddressLine2(e.target.value);

  const validateFields = () => {
    const errors = {};

    Object.entries({
      f: firstNames,
      l: lastName,
      addres,
      phoneNumber,
    }).forEach(([key, value = '']) => {
      if (value == null || value.trim().length === 0) {
        errors[key] = 'Required field';
      }
    });

    setErrors(errors);
    return errors;
  };

  return (
    <div className="review-info-box">
      <EzErrorModal
        headerText={'Validation Failed'}
        open={openValidationModal}
        onClose={() => setOpenValidationModal(false)}
      >
        <p> Some fields contain invalid information. </p>
        <p> Scroll up, fix the issues and try saving again. </p>
      </EzErrorModal>

      <div className="review-info-content-box">
        <div className="review-info-content-title">Welcome to ezpurim!</div>
        <div className="review-info-content-subtitle">
          {newCustomer
            ? `Please enter the information below to create an account`
            : `Please review your information below to ensure it’s up to date.`}
        </div>

        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const errors = validateFields();
            if (Object.keys(errors).length !== 0) {
              setOpenValidationModal(true);
              return;
            }

            if (newCustomer) {
              await createCustomer({
                addressLine2,
                email,
                firstNames,
                fullAddress: addres,
                lastName,
                newPassword,
                orgId: 2,
                phone: phoneNumber,
                pin,
                reciprocation: reciprocationFlag,
                titleId,
              });

              const loginStatus = await invisibleLogin({
                email,
                password: newPassword,
                setAuthenticated,
                setGreetingName,
                setLastName: setLastNameInContext,
                setPhonenumber
              });
              if (loginStatus) {
                history.push(LOGIN_ROUTE);
              }
            } else {
              await updateCustomer({
                titleId,
                firstNames,
                lastName,
                fullAddress: addres,
                addressLine2,
                phone: phoneNumber,
                reciprocation: reciprocationFlag,
              });
              setPhonenumber(phoneNumber)
              setWithExpiry(USER_PHONE_NUMBER, phoneNumber, 1669041441294)
              history.push(LOGIN_ROUTE);
            }
          }}
        >
          <div className="two-column-container">
            <TitlesCombo handleTitleIdChange={setTitleId} />
          </div>
          <div className="two-column-container">
            <EZInput
              errors={errors}
              className="first-names-field input-field"
              label="Fi&zwnj;rst Na&zwnj;me(s)"
              labelClassName="code"
              name="f"
              value={firstNames}
              handleValueChange={handleChangeFirstNames}
            />

            <EZInput
              errors={errors}
              className="last-name-field input-field"
              label="La&zwnj;st Na&zwnj;me"
              labelClassName="code"
              name="l"
              value={lastName}
              handleValueChange={handleChangeLastName}
            />
          </div>
          <div className="two-column-container">
            {!addressLoading &&
            <PlacesAutocomplete
              initialAddress={addres}
              onAddressChange={handleChangeAddress}
              errors={errors}
            />}
          </div>
          <div className="two-column-container">
            <EZInput
              className="address-line2-field input-field"
              optional
              label="Apartment or Suite"
              labelClassName="code"
              name="secondLine"
              value={addressLine2}
              handleValueChange={handleChangeAddressLine2}
              inputProps={{ placeholder: 'Apartment/Suite' }}
            />
            <EZInput
              errors={errors}
              className="phone-number-field input-field"
              label="Phone Number"
              labelClassName="code"
              name="phoneNumber"
              value={phoneNumber}
              handleValueChange={handleChangePhoneNumber}
            />
          </div>
          <button type="submit" className="register-account">
            Proceed to Order
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReviewInfoBox;
