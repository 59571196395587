import React from 'react';
import { Icon } from 'semantic-ui-react';

const SearchFieldIcon = ({ searchValue, setSearchValue, searchRef }) => {
  return searchValue ? (
    <Icon
      name="times circle"
      link
      onClick={() => {
        setSearchValue('');
        searchRef.current.focus();
      }}
    />
  ) : (
    <Icon name="search" />
  );
};

export default SearchFieldIcon;
